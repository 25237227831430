.tabs {
	display: flex;
	align-items: center;
	width: 100%;
	overflow: auto;
}

.tabs__tab {
	color: #18222b;
	flex-grow: 1;
	border: 1px solid transparent;
	background-color: #fafafa;
	padding: 16px;
	border-radius: 4px;
	transition-duration: 0.4s;
	font-size: 16px;
	font-weight: 600;
	line-height: calc(22/16);
	white-space: nowrap;
}

.tabs__tab:disabled {
	color: #A3B0B8;
}

.tabs__tab.active {
	border-color: #a3b0b8;
	background-color: #ffffff;
}


@media (max-width: 767.9px) {
	.tabs__tab {
		padding: 8px 20px;
	}
	.tabs {
		height: 100%;
	}
}

@media (max-width: 580.9px) {
	.tabs {
		width: 100vw;
		position: absolute;
		overflow: auto;
		padding-left: 20px;
		padding-right: 20px;
		left: -20px;
		top: 0;
	}

	.tabs::-webkit-scrollbar,
	.tabs::-webkit-scrollbar-track,
	.tabs::-webkit-scrollbar-thumb {
		background-color: transparent;
		height: 1px;
	}
}

