.research-block {
    --bg-light-grey: #F1F1F1;
    min-height: 214px;
    background-color: var(--bg-light-grey);
    padding-top: 80px;
    padding-bottom: 80px;
}

.research-block__description {
	font-size: clamp(18px, 4vw, 28px);
	font-weight: 600;
	line-height: 35px;
	max-width: 780px;
    margin-bottom: 42px;
    color: var(--body-color);
}

.research-block__wrap > .research-block__item:not(:last-child) {
    margin-bottom: 80px;
}

.download__btn {
	transition-duration: 0.4s;
	background-color: transparent;
	padding: 8px 16px;
	font-size: 12px;
	line-height: calc(22 / 14);
	font-weight: 500;
	border: 1px solid #a3b0b8;
	border-radius: 4px;
	color: #0f232e;
	display: inline-flex;
	gap: 0.5rem;
	align-items: center;
}

.download__btn__red {
	transition-duration: 0.4s;
	padding: 8px 16px;
	font-size: 16px;
	line-height: calc(22 / 16);
	font-weight: 600;
	border: 1px solid #a3b0b8;
	border-radius: 4px;
	color: white;
	display: inline-flex;
	gap: 0.5rem;
	align-items: center;
	background: #D50831;
}

.download__btn__red:hover {
	color: white;
}

.research-header {
    background-color: var(--body-color);
}

.research-header__content-wrap {
    padding-top: clamp(20px, 3vw, 83px);
    padding-bottom: clamp(20px, 40vw, 83px);
    min-height: clamp(270px, 80vw, 376px);
}

.research-header__content {
    display: flex;
	flex-direction: row;
    justify-content: space-between;
	flex-wrap: wrap;
}

.research-header__caption {
	font-weight: 500;
	font-size: clamp(4px, 3vw, 18px);
	line-height: clamp(10px, 4vw, 24px);
    max-width: 600px;
    padding-right: 20px;
    color: white;
	flex: 0 0 100%;
	padding-bottom: 28px;
}

.research-header__sm-caption {
	font-weight: 400;
	font-size: clamp(4px, 3vw, 14px);
	line-height: clamp(4px, 3vw, 22px);
    max-width: 280px;
    color: white;
	flex: 0 0 100%;
}

/* font-size: clamp(14px, 4vw, 24px); */
.research-header__content-wrap h1 {
    color: white;
    font-size: clamp(30px, 4vw, 55px);
    line-height: 55px;
    font-weight: 600;
    margin-bottom: 34px;
}



.download__dropdown {
	cursor: pointer;
}

.download__dropdown_offset {
	padding-bottom: 4px;
}

.download__btn.active,
.download__btn:hover,
.download__btn:focus,
.download__btn:active {
	background-color: #a3b0b8;
	color: inherit;
}
