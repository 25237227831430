.bar-chart {
	padding-top: 120px;
	padding-bottom: 105px;
	background: #d1dadf;
}

.bar-chart .section-title {
	margin-bottom: 16px;
}

.bar-chart__header {
	font-weight: 600;
	font-size: 28px;
	line-height: 35px;
	margin-bottom: 42px;
}

.bar-chart__text-wrap {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 80px;
}

.bar-chart__text {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 16px;
}

.bar-chart__text,
.bar-chart__header {
	max-width: 780px;
}

.bar-chart__right-text {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	color: #626f77;
	max-width: 279px;
}

.bar-chart__under-diagram {
	margin-top: 74px;
	display: flex;
	margin-bottom: 80px;
	justify-content: space-between;
}

.bar-chart__under-diagram-caption {
	font-weight: 600;
	font-size: 24px;
	line-height: calc(32/24);
	max-width: 680px;
}

.bar-chart__under-diagram-rigth {
	color: #626F77;
	font-size: 14px;
	line-height: calc(22/14);
	max-width: 280px;
	margin-left: 64px;
	min-width: 280px;
}

.bar-chart__wrap {
	padding-bottom: 25px;
}

@media (max-width: 991.9px) {
	.bar-chart__wrap {
		padding-bottom: 0;
	}

	.bar-chart__under-diagram {
		flex-direction: column;
		margin-bottom: 40px;
		margin-top: 0;
		justify-content: unset;
	}

	.bar-chart__under-diagram-rigth {
		margin-left: 0;
		width: auto;
		max-width: none;
		min-width: none;
	}

	.bar-chart__text-wrap {
		flex-direction: column;
		justify-content: unset;
		margin-bottom: 50px;
	}

	.bar-chart__under-diagram-caption {
		max-width: 100%;
		font-size: 18px;
		font-weight: 500;
	}

	.bar-chart {
		padding: 40px 8px;
	}

	.bar-chart__right-text {
		display: none;
	}

	.bar-chart__header {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 12px;
	}

	.bar-chart__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 12px;
	}

	.bar-chart__cards {
		display: block;
		user-select: none;
	}

	.bar-chart__card {
		width: auto;
		height: auto;
		max-width: 100%;
		min-width: unset;
		display: block;
		margin-bottom: 32px;
		margin-right: 0;
	}
}

@media (max-width:360px){
	.bc-item__category{
		font-size: 10px !important;
	}
}