.complaints {
	background-color: #f1f1f1;
}

.complaints__banner-wrap {
	/* padding-top: 130px; */
	height: 160px;
	box-sizing: content-box;
	position: relative;
}

.complaints__banner {
	padding: 32px 100px;
	border-radius: 8px;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #d1dadf;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 8px 15px 0px #1d243033;
}

.complaints__banner-info {
	width: calc(480 / 980 * 100%);
}

.complaints__banner-header {
	font-size: 40px;
	font-weight: 600;
	line-height: 50px;
	letter-spacing: 0em;
	margin-bottom: 12px;
}

.complaints__banner-text {
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.complaints__banner-buttons {
	background-color: #f1f1f1;
	border-radius: 4px;
	padding: 20px 28px;
	width: calc(480 / 980 * 100%);
	margin-left: 20px;
}

.complaints__banner-button {
	display: block;
	padding: 7px 24px 9px;
	text-align: center;
	margin-top: 16px;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	background-color: #2d5979;
	color: #ffffff;
	transition-duration: 0.4s;
}

.complaints__banner-button:hover {
	background-color: #ee1f48;
	color: #ffffff;
}

@media (max-width: 991.9px) {
	.complaints .container {
		margin: 0;
		padding: 0;
		max-width: unset;
	}

	.complaints__banner-wrap {
		height: auto;
	}

	.complaints__banner {
		position: static;
		display: block;
		border-radius: unset;
		box-shadow: unset;
		padding: 40px 20px;
	}

	.complaints__banner-info,
	.complaints__banner-buttons {
		width: 100%;
	}

	.complaints__banner-buttons {
		margin: 40px 0 0;
	}

	.complaints__banner-header {
		font-size: 24px;
		line-height: 28px;
	}

	.complaints__banner-button {
		padding: 16px 24px;
	}
}
