.download {
    border: 1px solid var(--darken-white-color);
    border-radius: 7px;
    min-width: 156px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.download__content {
    margin: auto;
}

.download-apk__title {
    font-size: 14px;
    color: white;
    font-weight: bold;
    line-height: 22px;
}

.download-apk__subtitle {
    font-size: 10px;
    color: var(--grey-color);
    font-weight: 400;
    line-height: 14px;
}

.download__link {
    padding: 10px 14px;
    width: 100%;
    display: flex;
}

.download-apk__right {
    margin-left: 9px;
}