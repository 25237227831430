.bc-diagram {
	height: 450px;
	position: relative;
	display: flex;
	align-items: flex-end;
}

.bc-diagram__text {
	position: absolute;
	width: 100%;
	left: 0;
	top: -90px;
	text-align: center;
}

.bc-diagram__percent {
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 4px;
}

.bc-diagram__category {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
}

.bc-diagram__group {
	width: calc(100% / 3);
	min-width: calc(100% / 3);
	padding-top: 100px;
	display: flex;
	align-items: flex-end;
	position: relative;
	transition-duration: 0.4s;
	background-color: transparent;
	border-radius: 4px;
	cursor: pointer;
	position: relative;
}

.bc-diagram__info {
	position: absolute;
	width: 40.6%;
	max-width: 480px;
	top: 34px;
	left: 0;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
}

@keyframes diagram {
	0% {
		transform: scaleY(0);
	}

	100% {
		transform: scaleY(1);
	}
}

.bc-diagram__info-mobile {
	display: none;
}

.bc-diagram__info-window-close,
.bc-diagram__info-window-cross {
	display: none;
}

.bc-diagram__group-title {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	max-width: 80%;
	top: 20px;
	font-size: 18px;
	line-height: calc(24 / 18);
	text-align: center;
	width: 210px;
	transition-duration: 0.4s;
	opacity: 0;
}

.bc-diagram__group-title.active {
	animation: opacity 2s;
	opacity: 1;
}

.bc-diagram__group-title span {
	font-size: 24px;
	font-weight: 600;
	line-height: calc(32 / 24);
	margin-right: 6px;
}

@media (max-width: 991.9px) {
	.bc-diagram__group-title span {
		margin-right: 0;
		margin-bottom: 4px;
		font-size: 18px;
		line-height: calc(24 / 18);
		font-weight: 500;
	}

	.bc-diagram__group-title {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 12px;
		line-height: calc(16 / 12);
		max-width: none;
		width: 110px;
		top: 0;
	}

	.bc-diagram {
		height: 350px;
	}

	.bc-diagram__group {
		padding-top: 80px;
	}

	.bc-diagram__info {
		font-size: 18px;
		line-height: 24px;
		width: 100%;
	}

	.bc-diagram__info {
		display: none;
	}

	.bc-diagram__info-mobile {
		display: block;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		padding-top: 21px;
	}

	.bc-diagram__a1 {
		height: 57px;
	}

	.bc-diagram__a2 {
		height: 81px;
	}

	.bc-diagram__a3 {
		height: 37px;
	}

	.bc-diagram__b1 {
		height: 150px;
	}

	.bc-diagram__b2 {
		height: 212px;
	}

	.bc-diagram__b3 {
		height: 186px;
	}

	.bc-diagram__c1 {
		height: 23px;
	}

	.bc-diagram__c2 {
		height: 62px;
	}

	.bc-diagram__c3 {
		height: 42px;
	}

	.bc-diagram__a1,
	.bc-diagram__b1,
	.bc-diagram__c1 {
		border-radius: 2px 0 0 0;
	}

	.bc-diagram__a2,
	.bc-diagram__b2,
	.bc-diagram__c2 {
		border-radius: 2px 2px 0 0;
	}

	.bc-diagram__a3,
	.bc-diagram__b3,
	.bc-diagram__c3 {
		border-radius: 0 2px 0 0;
	}

	.bc-diagram__text {
		width: 104px;
		left: calc(50% - 52px);
		top: -86px;
	}

	.bc-diagram__percent {
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 4px;
	}

	.bc-diagram__category {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	.bc-diagram__info-window {
		padding: 20px;
		position: fixed !important;
		top: 54% !important;
		left: 50% !important;
		right: auto !important;
		bottom: auto !important;
		transform: translate(-50%, -50%) !important;
		display: none;
		z-index: 1000;
		pointer-events: all !important;
		opacity: 1;
		width: 335px;
	}

	.bc-diagram__info-window.active {
		display: block;
		isolation: isolate;
	}

	.bc-diagram__info-window-header {
		margin-bottom: 8px;
		font-size: 20px;
	}

	.bc-diagram__info-window-text {
		margin-bottom: 20px;
	}

	.bc-diagram__info-window-close {
		display: block;
		background: #d50831;
		border-radius: 4px;
		padding: 8px 0px;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #ffffff;
		border: none;
		width: 100%;
	}

	.bc-diagram__info-window-square {
		display: none;
	}

	.bc-diagram__info-window-cross {
		display: block;
		border: none;
		background: transparent;
		position: absolute;
		right: 5px;
		top: -38px;
		width: 18px;
		height: 18px;
		padding: 0 !important;
	}

	.bc-diagram__info-window-cross svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media (min-width: 992px) and (max-width: 1399.9px) {
	.bc-diagram__category {
		font-size: 15px;
	}
}

@media (min-width: 992px) {
	.bc-diagram__group:hover {
		background-color: #a3b0b8;
	}

	.bc-diagram__group:hover .bc-diagram__info-window {
		opacity: 1;
	}

	.bc-diagram__group--1 {
		--left: 82.4%;
		--bottom: 78.3%;
	}

	.bc-diagram__group--2 {
		--left: 86.5%;
		--bottom: 85.2%;
	}

	.bc-diagram__group--3 {
		--left: 7%;
		--bottom: 95%;
	}
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 400px) {
	.bc-diagram__group-title {
		font-size: 10px;
	}
}

.bc-item {
	width: 33.333%;
	padding-left: 8px;
	padding-right: 8px;
	position: relative;
	transition-duration: 0.4s;
	z-index: 1;
}

.bc-item.active .bc-item__body {
	animation: diagram 1s;
	transform: scaleY(1);
}

.bc-item__header {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 8px;
	width: calc(100% - 16px);
	transition-duration: 1s;
}

.bc-item.active .bc-item__header {
	bottom: calc(100% + 16px);
}

.bc-item__category {
	order: 2;
	font-weight: 600;
	line-height: calc(16 / 12);
	font-size: 12px;
}

.bc-item__percent {
	order: 1;
	color: #d50831;
	font-size: 24px;
	line-height: calc(32 / 24);
	margin-bottom: 4px;
	font-weight: 600;
}

.bc-item__body {
	border-radius: 4px 4px 0 0;
	background-color: gray;
	max-height: 100%;
	height: 100%;
	transition-duration: 0.4s;
	transform: scaleY(0);
	transform-origin: 50% 100%;
	/* overflow: hidden; */
	color: #a3b0b8;
	font-size: 20px;
	text-transform: uppercase;
}

.bc-item__child {
	max-height: 100%;
	height: 100%;
	padding: 0 0.5rem;
	overflow: hidden;
}

.react-tooltip {
	padding: 12px 16px !important;
	text-transform: none !important;
	z-index: 100 !important;
	background: #fafafa !important;
	--rt-opacity: 1;
	left: 15% !important;
	border-radius: 2px !important;
}

.react-tooltip b,
.react-tooltip p {
	font-size: 12px !important;
	line-height: 16px !important;
	margin-bottom: 4px !important;
}

.react-tooltip span {
	text-transform: uppercase;
}

.react-tooltip b {
	margin-bottom: 4px !important;
}

.react-tooltip-arrow {
	width: 18px !important;
	height: 18px !important;
	bottom: -8px !important;
	left: 13px !important;
}

.bc-item__child:first-child {
	border-radius: 4px 4px 0 0;
}

.bc-item_color-A,
.bc-item__child:nth-child(1) {
	background-color: #2d5979;
}

.bc-item_color-B,
.bc-item__child:nth-child(2) {
	background-color: #2b445a;
}

.bc-item_color-C,
.bc-item__child:nth-child(3) {
	background-color: #2b3843;
}

.child-1 {
	background-color: #2d5979 !important;
}

.child-2 {
	background-color: #2b445a !important;
}

.child-3 {
	background-color: #2b3843 !important;
}

.bc-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: hidden;
	z-index: 0;
}

.bc-bg__line {
	position: absolute;
	width: 100%;
	border-top: 1px solid #a3b0b8;
	left: 0;
}

.bc-bg__line--down {
	border-top: 1px solid #2b3843;
	bottom: 0;
}

.bc-bg__line:nth-child(1) {
	bottom: 80%;
}

.bc-bg__line:nth-child(2) {
	bottom: 60%;
}

.bc-bg__line:nth-child(3) {
	bottom: 40%;
}

.bc-bg__line:nth-child(4) {
	bottom: 20%;
}

.bc-footer {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.bc-footer__item {
	padding: 0;
	margin-bottom: 16px;
}

.bc-footer__item:last-child {
	margin-bottom: 0;
}

.bc-footer__category {
	margin-bottom: 8px;
	font-size: 12px;
	line-height: calc(16 / 12);
}

.bc-footer__text,
.bc-footer__show-more {
	color: #757e85;
	font-size: 12px;
	line-height: calc(16 / 12);
}

.bc-footer__category-letters {
	text-transform: uppercase;
}

.bc-footer__category-description {
	margin-bottom: 0 !important;
	margin-top: 8px;
}

.bc-footer__show-more {
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
	display: block;
}

.bc-footer__text {
	margin-bottom: 8px;
}

@media (max-width: 991.9px) {
	.react-tooltip {
		display: none !important;
	}

	.bc-item__child {
		font-size: 14px;
		font-weight: 600;
		line-height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media (min-width: 992px) {
	.bc-item {
		padding-left: 19px;
		padding-right: 19px;
	}

	.bc-item__header {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		left: 19px;
		width: calc(100% - 38px);
	}

	.bc-item.active .bc-item__header {
		bottom: calc(100% + 11px);
	}

	.bc-item__category {
		font-size: 20px;
		order: 1;
	}

	.bc-item__percent {
		font-size: 40px;
		margin-bottom: 0;
		order: 2;
	}

	.bc-footer {
		flex-direction: row;
		margin-top: 20px;
		display: none;
	}

	.bc-footer__item {
		margin-bottom: 0;
		padding-left: 19px;
		padding-right: 19px;
		width: 33.333%;
	}

	.bc-footer__category {
		display: none;
	}
}
