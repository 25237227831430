
.header {
    min-height: 600px;
    background-color: var(--darken-blue-color);
    position: relative;
}

.header__left-block {
    margin: 80px 0;
    width: 50%;
    /* flex: 0 0 auto; */
}

.header__content {
    width: 100%;
}

.header__right-block {
    min-height: 600px;
    display: flex;
    align-items: end;
    justify-content: end;
    width: 50%;
    /* flex: 0 1 auto; */
}

.header__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
}

.header__left-block h1 {
    color: white;
    font-size: clamp(42px, 3vw, 55px);
    line-height: 55px;
    font-weight: 600;
    margin-bottom: clamp(40px, 3vw, 48px);
}

.header__caption {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
    max-width: 473px;
    padding-right: 20px;
    color: white;
    margin: 0;
	padding-bottom: 28px;
}

.header__sm-caption {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
    max-width: 280px;
    margin-bottom: 16px;
    color: var(--grey-color);
}

.header__download-buttons {
    max-width: 482px;
}

.header__image {
    position: relative;
    width: 507px;
    /* width: clamp(300px, 4vw, 507px); */
    /* height: 520px; */
}

.header__left-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    /* width: clamp(300px, 4vw, 507px); */
}

.header__right-image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 70%;
}

.instruction-block {
    background-color: var(--darken-white-color);
    padding: 80px 0;
}

@media (max-width: 991.9px) {
    .header__left-block {
        width: 100%;
    }
    
    .header__right-block {
        width: 100%;
        min-height: 558px;
        justify-content: center;
    }

    .instruction-block {
        padding: 40px 0;
    }
}

@media (max-width: 576px) {
    .header__download {
        width: 100%;
    }

    .header__download-buttons {
        max-width: 100%;
    }
}

@media (max-width: 558px) {
    .header__right-block {
        min-height: 100vw;
    }
}

.step {
    border-radius: 4px;
    background-color: var(--white-blue-color);
    /* width: 100px; */
    height: 340px;
    position: relative;
}

.qr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

.share {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 100%; */
    height: 100%;
}

.home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 100%; */
    height: 100%;
}

.instruction-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    /* margin-bottom: 40px; */
}

.dropdown-title > span {
    margin-left: 22px;
}

.drop-down--open > svg{
    /* rotate: 90deg; */
}

.drop-down--close > svg{
    rotate: -90deg;
}

.dropdown-title {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.drop-down--open, .drop-down--close > svg {
    margin-bottom: 5px;
    cursor: pointer;
}

.step__caption {
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 400;
}

.step__download-link {
    color: #E43801;
}