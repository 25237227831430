.form-field__caption {
	font-size: 14px;
	font-weight: 400;
	line-height: calc(22 / 14);
	margin-bottom: 6px;
}

.form-field__input {
	padding: 8px 16px;
	background-color: #ffffff;
	color: #2b3843;
	border-radius: 4px;
	margin: 0;
	border: none;
	outline: unset;
	font-size: 16px;
	font-weight: 500;
	line-height: calc(22 / 16);
	width: 100%;
}

.form-field__input.error {
	padding: 7px 15px;
	border: 1px solid #D50831;
}

.form-field__error {
	color: #D50831;
	margin-top: 4px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.form-field__input::placeholder {
	color: #959ba1;
}
