.topnav.api,
.topnav.testing,
.topnav.active-menu {
	background-color: #18222b;
}

.topnav__mob-menu {
	color: #ffffff;
	width: 100%;
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition-duration: 0.4s;
}

.topnav__mob-menu.active {
	height: calc(var(--vh, 1vh) * 100 - 76.16px);
	opacity: 1;
}

.topnav__mob-menu-wrap {
	height: 100%;
}

.topnav__mob-menu-content {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 40px 0;
}

.topnav__menu-contacts {
	margin-top: auto;
	padding-top: 40px;
	border-top: 1px solid #919598;
}

.topnav__menu-link,
.topnav__menu-link:hover {
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	display: block;
	color: inherit;
}

.topnav__menu-link:not(:last-child) {
	margin-bottom: 16px;
}

.topnav__menu-header {
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	margin-bottom: 40px;
}

.topnav__menu-button {
	display: none;
	background-color: transparent;
	border: none;
	outline: none;
	width: 40px;
	height: 40px;
	margin-left: 4px;
	position: relative;
}

.topnav__menu-button-icon {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition-duration: 0.4s;
}

.topnav__menu-button svg {
	width: 20px;
	height: 20px;
}

.topnav__menu-button-icon.open {
	opacity: 1;
}

.topnav__menu-button-icon.close {
	opacity: 0;
}

.active .topnav__menu-button-icon.open {
	opacity: 0;
}

.active .topnav__menu-button-icon.close {
	opacity: 1;
}

.topnav__wrap .container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.topnav__wrap {
	padding: 16px 0;
}

.topnav__contacts {
	display: flex;
	align-items: center;
}

.topnav__lk {
	padding: 7px 24px 9px;
	border-radius: 4px;
	background-color: #2d5979;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	color: #ffffff;
	margin-left: 44px;
	transition-duration: 0.4s;
}

.topnav__logo,
.topnav__logo > * {
	display: block;
	width: 132px;
	height: 49.73px;
}

.topnav__logo svg {
	width: 100%;
	height: 100%;
}

.topnav__menu {
	display: flex;
	flex-direction: row;
}

.topnav__menu-item {
	margin-left: 68px;
	color: #f3f4f5;
}

.topnav__menu-link,
.topnav__menu-item {
	border-bottom: 1px solid transparent;
	transition-duration: 0.4s;
	width: fit-content;
}

.topnav__menu-item:hover,
.topnav__menu-link:hover {
	border-color: #f3f4f5;
}

.topnav__menu-item:first-child {
	margin-left: 0;
}

.topnav__menu-item span,
.topnav__menu-item a {
	font-size: 16px;
	font-weight: 600;
	line-height: calc(22 / 16);
	color: #f3f4f5;
}

.topnav__toggle {
	width: 24px;
	min-width: 24px;
	height: 14px;
	cursor: pointer;
	position: relative;
}

.topnav__toggle-line {
	width: 100%;
	height: 2px;
	background-color: #ffffff;
	border-radius: 4px;
	position: absolute;
	left: 0;
	transition-duration: 0.4s;
}

.topnav__toggle-line-top {
	top: 0;
}

.topnav__toggle-line-center {
	top: 50%;
	transform: translateY(-50%);
}

.topnav__toggle-line-bottom {
	bottom: 0px;
	opacity: 1;
}

.topnav__toggle.active .topnav__toggle-line-top {
	transform: translateY(-50%) rotate(-45deg);
	top: 50%;
}

.topnav__toggle.active .topnav__toggle-line-center {
	transform: translateY(-50%) rotate(45deg);
}

.topnav__toggle.active .topnav__toggle-line-bottom {
	opacity: 0;
}

.topnav__menu-mobile {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
	left: 0;
	background-color: #18222b;
	transition-duration: 0.4s;
	transform: translateY(-150%);
	z-index: 5;
}

.topnav__menu-mobile.active {
	transform: translateY(0);
}

.topnav__menu-mobile-wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 124px;
	padding-bottom: 40px;
	height: 100%;
	min-height: 440px;
}

.topnav__menu-mobile-links-item {
	margin-bottom: 40px;
}

.topnav__menu-mobile-links-item a {
	font-weight: 600;
	font-size: 24px;
	line-height: calc(32 / 24);
	color: #f3f4f5 !important;
}

.topnav__menu-mobile-footer-title {
	font-size: 14px;
	margin-bottom: 16px;
	line-height: calc(22 / 14);
	color: #84898e;
}

.topnav__menu-mobile-footer-item {
	margin-bottom: 16px;
}

.topnav__menu-mobile-footer-item:last-child {
	margin-bottom: 0;
}

.topnav__menu-mobile-footer-item a {
	font-size: 14px;
	line-height: calc(22 / 14);
	font-weight: 500;
	color: #f1f1f1;
}

@media (min-width: 992px) {
	.topnav__toggle {
		display: none;
	}

	.topnav__mob-menu {
		display: none;
	}

	body {
		overflow: auto !important;
	}

	.topnav__lk:hover {
		background-color: #d50831;
		color: #ffffff;
	}

	.topnav.active-menu {
		background-color: transparent;
	}

	.topnav.api,
	.topnav.testing {
		background-color: #18222b;
	}
}

@media (max-width: 991.9px) {
	.topnav.active {
		padding-top: 36px;
	}

	.topnav__wrap .container {
		align-items: center;
	}

	.topnav__wrap.active {
		position: fixed;
		top: 28px;
		left: 0;
		width: 100%;
		z-index: 6;
	}

	.topnav__logo {
		width: 118px;
		min-width: auto;
		height: 36px;
		position: relative;
	}

	.topnav__logo,
	.topnav__toggle {
		position: relative;
		z-index: 6;
	}

	.topnav__logo svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.topnav .container {
		align-items: flex-start;
	}

	.topnav.api {
		background-color: #2B445A;
	}

	.topnav.testing {
		background-color: #2d5979;
	}

	.topnav__logo,
	.topnav__logo > * {
		width: 117.22px;
		height: 44.16px;
	}

	.topnav__lk {
		background-color: inherit;
	}

	.topnav__menu-item {
		margin: 0 0 36px 0;
	}

	.topnav__menu-button {
		display: block;
	}

	.topnav__menu-desktop {
		display: none;
	}

	.topnav__lk {
		margin-left: 0;
	}
}

@media (max-width: 767px) {
	.topnav__menu-desktop {
		display: none;
	}
}

@media (max-width: 575.9px) {
	.topnav__mob-menu-content {
		padding: 40px 8px;
	}
}
