.carrier-api-content {
	background-color: #18222b;
}

.carrier-api-content__info {
	display: flex;
	justify-content: center;
}

.carrier-api-content__left-block {
	background-color: #2b445a;
	margin-left: auto;
}

.carrier-api-content__right-block {
	background-color: #f1f1f1;
}

.carrier-api-content__left-block,
.carrier-api-content__right-block {
	width: 50%;
	display: flex;
	align-items: center;
}

.carrier-api-content__center-block {
	max-width: 590px;
	width: 100%;
}

.carrier-api-content__center-block.left {
	margin-left: auto;
	padding: 88px 110px 88px 0;
	color: #ffffff;
}

.carrier-api-content__center-block.right {
	padding: 80px 0 80px 110px;
}

.carrier-api-content__header {
	font-size: 38px;
	font-weight: 600;
	line-height: 47px;
	margin-bottom: 34px;
}

.carrier-api-content__text {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 0px;
}

.carrier-api-content__text:last-child {
	margin-top: 30px !important;
}

.carrier-api-content__sign-up {
	background-color: #d50831;
	border: none;
	padding: 8px 24px;
	border-radius: 4px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	line-height: calc(22 / 16);
	transition-duration: 0.4s;
}

.carrier-api-content__sign-up:hover {
	background-color: #a70a29;
}

.carrier-api-content__text:not(:first-child) {
	margin-top: 8px;
}

.carrier-api-content__field-wrap:not(:last-child) {
	margin-bottom: 20px;
}

.carrier-api-content__text.with-dash {
	padding-left: 34px;
	position: relative;
}

.carrier-api-content__dash {
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 24px;
}

.carrier-api-content__field-bottom-caption {
	margin-top: 4px;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #6b7880;
}

.carrier-api-content__mail-sent-header {
	font-size: 24px;
	font-weight: 600;
	line-height: 28px;
	margin-bottom: 10px;
}

.carrier-api-content__mail-sent-text {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 0;
}

@media (max-width: 1399.9px) {
	.carrier-api-content__center-block {
		max-width: 570px;
	}
}

@media (max-width: 1199.9px) {
	.carrier-api-content__center-block {
		max-width: 480px;
	}

	.carrier-api-content__center-block.left {
		padding: 79px 32px 79px 0;
	}

	.carrier-api-content__center-block.right {
		padding: 79px 0px 79px 32px;
	}

	/* .carrier-api-content__header {
		font-size: 42px;
	} */
}

.carrier-api {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.carrier-api-content__info {
	flex-grow: 1;
}

@media (max-width: 991.9px) {
	.carrier-api-content__info {
		display: flex;
		flex-direction: column;
	}

	.carrier-api-content__right-block {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.carrier-api-content__left-block,
	.carrier-api-content__right-block {
		width: 100%;
	}

	.carrier-api-content__center-block {
		max-width: unset;
		margin-left: 0 !important;
		padding: 80px 20px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.carrier-api-content__center-block.right {
		padding: 40px 20px !important;
	}

	.carrier-api-content__block-wrap {
		max-width: 720px;
		width: 100%;
	}

	.carrier-api-content__header {
		margin-bottom: 40px;
	}

	.carrier-api-content__sign-up {
		width: 100%;
	}

	.carrier-api-content__text:last-child {
		margin-top: 20px !important;
	}

	.carrier-api-content__mail-sent-header {
		font-size: 20px;
		margin-bottom: 20px;
	}
}

@media (max-width: 767.9px) {
	.carrier-api-content__block-wrap {
		max-width: 540px;
	}
}
