.article-block {
	background: #F1F1F1;
}

.article-header-block {
	min-height: 572px;
	color: white;
	display: flex;
	flex-direction: column;
	background:
			url("/src/assets/articleImg/main-bg.png"),
			lightgray 50% / cover no-repeat;
	background-color: #2B3843CC;
	background-size: cover;
	background-position: right -180px bottom 0;
}

.article-header-block__container, .article-table-contents-block__container {
	padding: 0 20px 64px 20px;
	display: flex;
	align-items: end;
	margin-top: auto;
}

.article-table-contents-block__container {
	width: 100%;
}

.article-header-block h1 {
	font-size: 32px;
	line-height: 40px;
	margin: auto 0 0;
	max-width: 780px;
}

.article-block__wrap {
	display: flex;
	flex-direction: column-reverse;
	align-items: start;
	justify-content: space-between;
	padding-top: 20px;
	width: 100%;
	position: relative;
}

.article-table-contents-block {
	width: 100%;
}

.article-table-contents-block_content {
	background: #2D59790D;
	border-radius: 4px;
	width: 100%;
	padding: 20px;
}

.article-table-contents-block_content ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.article-table-contents-block_content ul > li {
	font-size: 16px;
	cursor: pointer;
}

.article-table-contents-block_content ul > li:first-of-type {
	font-size: 18px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.article-table-contents-block_content .article-table-contents-block_content__hidden > li:first-of-type > svg {
	rotate: 180deg;
}

.article-table-contents-block_content ul > li.active {
	color: #d50831;
}

.article-table-contents-block_content ul > li  + li {
	margin-top: 14px;
}

.article-table-contents-block_share {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 14px;
}

.article-table-contents-block_share p {
	display: flex;
	align-items: center;
	margin: 0;
	padding: 10px;
	width: calc(50% - 10px);
}

.article-table-contents-block_share p > svg {
	margin-right: 5px;
	width: 20px;
	height: 20px;
	flex: 0 0 20px;
}

.article-table-contents-block_content__hidden > li:not(:first-of-type) {
	display: none;
}

.article-content-block {
	width: 100%;
	padding: 20px;
	font-weight: 500;
}

.article-content-block article + article {
	margin-top: 20px;
}

.article-content-block_counters {
	display: flex;
	flex-direction: column;
}

.article-content-block_counters div + div {
	margin-top: 32px;
}

.article-content-block_counter {
	display: flex;
	align-items: baseline;
	color: #d50831;
	font-weight: 600;
}

.article-content-block_counter__big {
	font-size: 48px;
	margin-right: 10px;
}

.article-content-block-section {
	font-size: 14px;
	font-weight: 600;
	color: #d50831;
}

.article-content-block-title {
	font-size: 24px;
	font-weight: 600;
}

.article-content-block-subtitle {
	font-size: 18px;
	font-weight: 600;
}

.article-content-block-subtitle__red {
	font-size: 18px;
	font-weight: 600;
	color: #EE1F48;
	margin-bottom: 16px;
}


.chip-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;
}

.chip {
	display: flex;
	align-items: center;
	border-radius: 4px;
	padding: 8px;
	background: #D1DADF;
	color: #2D5979;
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
}

.chip span + svg {
	margin-left: 5px;
}

.article-img {
	width: 100%;
}

.services-dropdown {
	position: relative;
}

.services-dropdown_selected {
	padding: 8px 16px;
	border-radius: 8px;
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.services-dropdown__open > .services-dropdown_selected > svg {
	rotate: 180deg;
}

.services-dropdown_options {
	position: absolute;
	bottom: 20px;
	background: white;
	list-style: none;
	width: 100%;
	border-radius: 8px;
	padding: 0;
	margin: 0;
	overflow: hidden;
	transform: translateY(calc(100% + 32px));
	display: none;
}

.services-dropdown_options__open {
	display: block;
}

.services-dropdown_options > li {
	padding: 8px 16px;
}

.services-dropdown_options > li:hover {
	background: #D1DADF;
}

.hb-block-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	border-radius: 4px;
	overflow: hidden;
}

.hb-block-wrap > div {
	padding: 14px 14px 14px 2%;
	height: 36px;
	font-size: 12px;
	box-sizing: content-box;
}

.hb-block-wrap > div:nth-child(1) {
	background: #2B3843;
	color: white;
}

.hb-block-wrap > div:nth-child(2) {
	background: #2D5979;
	color: white;
}

.hb-block-wrap > div:nth-child(3) {
	background: #A3B0B8;
	color: white;
}

.hb-block-wrap > div:nth-child(4) {
	background: #D1DADF;
	color: #2D5979;
}

.hb-block-legend {
	font-size: 12px;
	font-weight: 400;
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.hb-block-legend div {
	display: flex;
	align-items: center;
	height: 20px;
	margin-right: 16px;
}

.hb-block-legend div span + span {
	margin-left: 4px;
}

.hb-block-legend div span:first-of-type {
	display: block;
	height: 8px;
	width: 8px;
	border-radius: 100%;
}

.hb-block-legend > div:nth-child(1) > span:first-of-type {
	background: #2B3843;
}

.hb-block-legend > div:nth-child(2) > span:first-of-type {
	background: #2D5979;
}

.hb-block-legend > div:nth-child(3) > span:first-of-type {
	background: #A3B0B8;
}

.hb-block-legend > div:nth-child(4) > span:first-of-type {
	background: #D1DADF;
}

.services-chips {
	display: none;
}

.show-more {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 16px;
	margin-top: 24px;
}

.show-more span + svg {
	margin-left: 5px;
}

.show-more.show-more__open > svg {
	rotate: 180deg;
}

@media (min-width: 768px) {
	.article-content-block {
		max-width: 780px;
	}

	.article-header-block {
		background-position: right 0 bottom 0;
	}

	.article-header-block h1, .article-content-block-title {
		font-size: 40px;
		line-height: 44px;
	}

	.article-block {
		font-size: 18px;
		line-height: 28px;
	}

	.article-block__wrap {
		flex-direction: row;
		padding-bottom: 200px;
	}

	.article-table-contents-block {
		max-width: 282px;
		position: sticky;
		top: 20px;
	}

	.article-table-contents-block_content ul > li:first-of-type > svg {
		display: none;
	}

	.article-table-contents-block_content__hidden > li:not(:first-of-type) {
		display: block;
	}

	.article-table-contents-block_share {
		flex-direction: column;
	}

	.article-table-contents-block_share p {
		width: 100%;
		cursor: pointer;
	}

	.article-content-block_counters {
		flex-direction: row;
	}

	.article-content-block_counters > div {
		max-width: 40%;
	}

	.article-content-block_counters div + div {
		margin-top: 0;
		margin-left: 60px;
	}

	.article-content-block_counter {
		font-size: 16px;
		line-height: 48px;
	}

	.article-content-block_counter__big {
		font-size: 64px;
		line-height: 64px;
	}

	.services-dropdown {
		display: none;
	}

	.services-chips {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		align-items: center;
	}

	.services-chip {
		border-radius: 4px;
		padding: 8px;
		background: white;
		cursor: pointer;
		font-size: 14px;
	}

	.services-chip__active {
		background: #d50831;
		color: white;
	}
}

.active-scroll-spy {
	background-color: yellowgreen;
	border-radius: 15px;
}
