.header-block {
	/* padding-top: 16px; */
	background-color: var(--body-color);
	background:
		url("/src/assets/main-bg.jpg"),
		lightgray 50% / cover no-repeat;
	background-size: cover;
	background-position: right 0 bottom 0;
}

.header-block__container {
	padding: 114px 0;
}

.header-block h1 {
	font-size: 55px;
	margin-bottom: 32px;
}

.header-block__info {
	color: #ffffff;
	display: flex;
	flex-direction: column;
}

/* .header-block h1 {
	font-weight: 600;
	line-height: calc(92 / 90);
} */

.header-block__caption {
	font-weight: 500;
	font-size: 18px;
	line-height: calc(32 / 23);
	/* color: #747a80; */
	margin-bottom: 47px;
}

.header-block__caption span {
	color: #ffffff;
}

.header-block__rating {
	display: flex;
}

.header-block__rating-input {
	margin-right: 40px;
}

.header-block__rating-input input {
	padding-left: 12px;
	padding-right: 12px;
	border: none;
	border-bottom: 2px solid #d9d9d9;
	padding-bottom: 10px;
	background-color: transparent;
	color: #ffffff;
	font-weight: 400;
	font-size: 15px;
	line-height: calc(18 / 15);
	outline: none;
	width: 100%;
}

.header-block__rating-input input::placeholder {
	color: #747a80;
}

.header-block__rating-btn {
	border: none;
	background-color: #d50831;
	border-radius: 2px;
	padding: 8px 23px;
	font-weight: 500;
	font-size: 14px;
	line-height: calc(22 / 14);
	color: #ffffff;
	transition-duration: 0.4s;
}

.header-block__rating-btn:hover,
.header-block__rating-btn:focus,
.header-block__rating-btn:active {
	background-color: #a70a29;
	color: #fff;
}

.header-block__thesis {
	display: flex;
}

.header-block__text {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	margin-left: 8px;
	margin-bottom: 0;
}

.header-block__thesis:not(:first-child) {
	margin-top: 8px;
}

@media (min-width: 992px) {
	/* .header-block h1 {
		font-size: 70px;
		max-width: calc(100% - 480px);
	} */
	.header-block__caption-wrap {
		/* margin-left: auto; */
		/* margin-top: 95px;
		width: 681px; */
	}
	.header-block__rating {
		align-items: flex-end;
	}
	.header-block__rating-input {
		flex-grow: 1;
	}
}

@media (min-width: 1200px) {
	/* .header-block h1 {
		font-size: 84px !important;
	} */
}

@media (min-width: 1400px) {
	/* .header-block h1 {
		font-size: 92px !important;
	} */
}

@media (max-width: 991.9px) {
	.header-block h1 {
		font-size: 42px;
		margin-bottom: 40px;
	}

	.header-block__rating {
		flex-direction: column;
	}

	.header-block__caption-wrap {
		width: 100%;
	}

	.header-block__rating-input {
		margin-right: 0;
		width: 100%;
		margin-bottom: 32px;
	}

	.header-block__rating-btn {
		padding-top: 16px;
		padding-bottom: 16px;
		font-size: 16px;
		font-weight: 600;
	}

	.header-block {
		padding-bottom: 40px;
		/* padding-top: 16px; */
		display: flex;
		flex-direction: column;
	}

	.header-block .container {
		flex-grow: 1;
	}

	.header-block__caption {
		font-size: 16px;
	}

	.header-block > .container,
	.topnav__wrap .container,
	.rating-table .container,
	.rating-search .container {
		padding: 0 20px;
	}

	.header-block {
		height: 100vh;
		min-height: 600px;
		/* padding-top: 16px; */
	}

	.header-block__info {
		padding-top: 23.4vh;
	}

	.header-block__caption-wrap {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.header-block__rating {
		margin-top: auto;
	}
	.header-block__info {
		height: 100%;
	}
}

@media (max-height: 690px) {
	.header-block__info {
		padding-top: 10vh;
	}
}

@media (max-width: 460px) {
	.header-block {
		background:
			url("/src/assets/bg-mobile.png"),
			lightgray 50% / cover no-repeat;
		background-size: cover;
		background-position: right 0 bottom 0;
	}
}

@media (max-width: 360px) {
	.header-block h1 {
		font-size: 34px;
	}
	.header-block__caption {
		/* padding-left: 42px; */
		font-size: 13px;
	}
	.header-block__rating-input input {
		font-size: 13px;
	}
	.header-block__rating-btn {
		font-size: 14px;
		padding: 8px 16px;
	}
}

.article-announce-block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 40px 20px 20px 20px;
}

.article-announce-block img {
	margin-top: 24px;
}

.article-announce-block h3 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
}

.article-announce-block p {
	margin: 0 0 24px;
	padding: 0;
}

.article-announce-block a {
	appearance: none;
	width: 100%;
}

.article-announce-block a button {
	appearance: none;
	outline: none;
	border: none;
	font-size: 16px;
	font-weight: 600;
	color: white;
	background: #D50831;
	border-radius: 4px;
	padding: 16px 24px;
	width: 100%;
}

.article-announce-block .article-announce-img {
	width: 100%;
}

@media (min-width: 768px) {
	.article-announce-block {
		max-width: 1180px;
		margin: 0 auto;
		padding: 0 48px;
		flex-direction: row;
		align-items: start;
		justify-content: space-between;
		border-radius: 4px;
		overflow: hidden;
		background: white;
		position: absolute;
		left: 50%;
		transform: translateX(-50%) translateY(-40%);
		box-shadow: 0 8px 15px 0 #1D243033;
	}

	.article-announce-block > div {
		max-width: 580px;
	}

	.article-announce-block h3 {
		font-size: 32px;
		line-height: 36px;
		margin-top: 40px;
	}

	.article-announce-block p {
		font-size: 18px;
		line-height: 24px;
		margin-top: 24px;
	}

	.article-announce-block a {
		max-width: 227px;
	}

	.article-announce-block img {
		padding: 0;
		margin: 0;
		max-width: 327px;
	}

	.article-announce-block a button {
		padding: 8px 24px;
		width: auto;
		font-size: 14px;
		max-width: 327px;
	}
}
