.search__container {
    position: relative;
    border-bottom: 2px solid #a3b0b8;
    display: flex;
    align-items: center;
}

.search-input-btn {
    border: none;
    background-color: transparent;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    line-height: calc(16 / 12);
    position: absolute;
    right: 0;
}

.search-input-btn svg {
    width: 10px;
    height: 10px;
}

.search-input {
    flex-grow: 1;
    width: auto;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    background-color: transparent;
    color: #2b3843;
    padding: 8px 12px;
    padding-right: 2.5rem;
}

.search__list {
    display: none;
    flex-flow: column;
    overflow: auto;
    position: absolute;
    bottom: -4px;
    width: 100%;
    translate: 0 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 10rem;
    background: #fff;
}

.search__list li {
    padding: 8px 12px;
    cursor: pointer;
}

.search-input:focus ~ .search__list,
.search__list:hover {
    display: flex;
}

.search-input::placeholder {
    color: #2b3843;
}

@media (min-width: 992px) {
    .search-input-wrap {
        margin-bottom: 0;
        width: 340px;
    }

    .search-input {
        width: 452px;
    }
}

@media (max-width: 991.9px) {
    .search-input {
        padding-right: 12px;
        min-width: 224px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .search-input-btn {
        padding-right: 6px;
    }
}

@media (max-width: 360px) {
    .search-input {
        font-size: 12px;
    }
}
