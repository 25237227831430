.about-rating__header {
	font-size: 28px;
	font-weight: 600;
	line-height: calc(35 / 28);
	margin-bottom: 40px;
	max-width: 880px;
}

.about-rating__text {
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	max-width: 880px;
	margin-bottom: 40px;
	margin-bottom: 8px;
}

.about-rating__parameter-name {
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	margin-bottom: 4px;
}

.about-rating__parameter-description {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 0;
}

.about-rating__parameters {
	--bs-gutter-x: 50px;
	--bs-gutter-y: 40px;
	margin-bottom: 40px;
}

.rating-table__th--1.methodics {
	padding: 12px 32px 8px;
}
.rating-table__td--1.methodics {
	padding: 8px 20px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	color: #182e42;
}

.rating-table__th--2.methodics {
	padding: 12px 24px 8px;
}

.rating-table__col-2 {
	padding: 8px 24px;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}


.rating-table__th--1.methodics,
.rating-table__td--1.methodics {
	width: 120px;
}

.rating-table__th--2.methodics,
.rating-table__col-2 {
	flex-grow: 1;
	max-width: calc(100% - 120px);
}

.about-rating__footnote {
	display: flex;
	color: #84898E;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	padding-bottom: 40px;
	margin-top: 48px;
}

.about-rating__footnote-star {
	margin-right: 8px;
}

.about-rating__footnote-text,
.about-rating__footnote-star {
	margin-bottom: 0;
}

.about-rating__table-wrap {
	overflow: auto;
	width: 100%;
}

.rating-table__table-head.methodics,
.rating-table__table-tr.methodics {
	min-width: 644px;
}

@media ((min-width: 768px) and (max-width: 991.9px)) {
	.about-rating__parameter.ord-4 {
		order: 4;
	}
	.about-rating__parameter.ord-5 {
		order: 5;
	}
}

@media (max-width: 1199.9px) {
	.about-rating__parameters {
		--bs-gutter-x: 40px;
	}
}

@media (max-width: 991.9px) {
	.about-rating__parameters {
		--bs-gutter-x: 32px;
		--bs-gutter-y: 20px;
	}
	.about-rating__header {
		font-size: 24px;
		margin-bottom: 40px;
	}

	.about-rating__text {
		margin-bottom: 20px;
	}

	.about-rating__parameter.first {
		margin-top: 40px;
	}
	.about-rating__parameter.second {
		margin-top: 8px;
	}

	.about-rating__footnote {
		margin-top: 40px;
		line-height: 20px;
		padding-bottom: 0;
	}

	.rating-table__th--1.methodics {
		padding: 12px 10px 8px;
		font-size: 14px;
		display: flex;
		justify-content: center;
	}

	.rating-table__th.methodics {
		font-size: 14px;
	}

	.rating-table__th--1.methodics,
	.rating-table__td--1.methodics {
		width: 100px;
	}
	.rating-table__th--2.methodics,
	.rating-table__col-2 {
		max-width: calc(100% - 100px);
	}
}

@media (max-width: 767.9px) {
	.about-rating__table-wrap {
		position: relative;
		width: 100%;
		height: 1146px;
		overflow: visible;
	}
	.rating-table__table.methodics {
		position: absolute;
		padding-left: calc(50vw - 270px);
		padding-right: calc(50vw - 270px);
		width: 100vw;
		top: 0;
		left: calc(270px - 50vw);
	}

	.rating-table__table.methodics::-webkit-scrollbar,
	.rating-table__table.methodics::-webkit-scrollbar-thumb,
	.rating-table__table.methodics::-webkit-scrollbar-track {
		background-color: transparent;
	}
}

@media (max-width: 575.9px) {
	.rating-table__table.methodics {
		position: absolute;
		padding-left: 20px;
		padding-right: 20px;
		left: -20px;
	}
}
