.rating-modal {
	position: fixed;
	z-index: 1000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -51%);
	width: calc(100vw - 40px);
	max-width: 1180px;
	background-color: #fafafa;
	box-shadow: 0px 8px 15px rgba(29, 36, 48, 0.2);
	border-radius: 4px;
	--height: calc(100vh - 40px);
	max-height: var(--height);
	padding: 0;
	--height-result: calc(100vh - 118px);
	--height-result-wrap: calc(100vh - 228px);
}

.rating-modal.safari {
	--height: calc(100vh - 120px);
	--height-result: calc(100vh - 198px);
	--height-result-wrap: calc(100vh - 298px);
}

.rating-modal .rating-search__result {
	display: block;
	margin-top: 0;
}

.rating-modal .rating-search__result-wrap {
	margin-bottom: 0;
}

.rating-modal__close {
	position: absolute;
	cursor: pointer;
}

.rating-modal__close svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.rating-modal .rating-search__result-wrap {
	overflow: auto;
}

.rating-modal__mobile-close {
	padding: 20px;
	box-shadow: 0px -6px 12px 1px rgba(0, 0, 0, 0.13);
}

.rating-modal__btn {
	width: 100%;
	padding: 8px;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	line-height: calc(22 / 14);
	text-align: center;
	color: #ffffff;
	background-color: #d50831;
	border: none;
}

@media (min-width: 992px) {
	.rating-modal {
		width: calc(100vw - 100px);
		padding: 40px;
		max-height: calc(100vh - 40px);
	}

	.rating-modal__close {
		position: absolute;
		top: 40px;
		right: 40px;
		width: 16px;
		height: 16px;
	}

	.rating-modal .rating-search__result {
		max-height: calc(100vh - 120px);
	}

	.rating-modal .rating-search__result-wrap {
		max-height: calc(100vh - 230px);
		/* height: calc(100vh - 230px); */
		height: 445px;
	}

	.rating-modal__mobile-close {
		display: none;
	}
}

@media (max-width: 991.9px) {
	.rating-modal__close {
		display: none;
		right: 10px;
		bottom: calc(100% + 20px);
		width: 20px;
		height: 20px;
	}

	.rating-modal .rating-search__code-wrap {
		margin-bottom: 0;
		padding: 0 20px 20px;
		position: relative;
	}

	.rating-modal .rating-search__code-wrap::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0px;
		width: 100%;
		height: 1px;
		background-color: #d1dadf;
	}

	.rating-modal .rating-search__result {
		max-height: var(--height-result);
		min-height: var(--height-result);
	}

	.rating-modal .rating-search__result-wrap {
		max-height: var(--height-result-wrap);
		min-height: var(--height-result-wrap);
		overflow-x: hidden;
	}

	.rating-search__result-wrap::before {
		display: none;
	}

	.rating-modal {
		transform: translate(-50%, -50%);
		min-height: var(--height);
	}

	.rating-search__result {
		padding-top: 20px;
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 20px;
	}
}

@media (max-width: 414px) {
	.rating-modal {
		--height-result-wrap: calc(100vh - 250px);
	}

	.rating-modal.safari {
		--height-result-wrap: calc(100vh - 320px);
	}
}

@media (max-width: 360px) {
	.rating-modal {
		width: calc(100vw - 30px);
	}

	.rating-search__diagram {
		margin-right: 20px;
	}
}
