.rating-search {
	background-color: #f1f1f1;
	padding-top: 60px;
	padding-bottom: 60px;
}

.rating-search__wrap {
	box-shadow: 0px 8px 15px rgba(29, 36, 48, 0.2);
	border-radius: 4px;
	background-color: #fafafa;
	padding: 32px 20px;
}

.rating-search__wrap h2 {
	text-align: center;
	font-weight: 600;
	font-size: 24px;
	line-height: 100%;
	margin-bottom: 32px;
}

.rating-search__wrap.active h2 {
	display: none;
}

.rating-search__diagram.active .diagram-title {
	opacity: 0.4;
}

.rating-search__search-wrap {
	display: flex;
	flex-direction: column;
}

.rating-search__wrap h4 {
	font-weight: 600;
	font-size: 20px;
	line-height: 200%;
	margin-bottom: 19px;
	display: none;
	text-align: center;
}

.rating-search__wrap.active h4 {
	display: block;
}

.rating-search__search {
	display: flex;
	align-items: flex-end;
}

.rating-search__search-field {
	padding-top: 6px;
	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 8px;
	border: none;
	outline: none;
	background-color: transparent;
	border-bottom: 1px solid #d9d9d9;
	width: 100%;
}

.rating-search__search-field-wrap {
	width: 100%;
	position: relative;
}

.rating-search__search-btn {
	transition-duration: 0.4s;
	font-weight: 600;
	font-size: 14px;
	line-height: calc(18 / 14);
	color: #ffffff;
	text-align: center;
	border-radius: 4px;
	padding: 8px 63px;
	border: none;
	background-color: #d50831;
	margin-left: 21px;
	transition-duration: 0.4s;
}

.rating-search__search-btn:hover,
.rating-search__search-btn:focus,
.rating-search__search-btn:active {
	background-color: #a70a29;
}

.rating-search__wrap.active .rating-search__search-btn {
	padding: 8px 32px;
	background-color: #18222b;
}

.rating-search__result {
	margin-top: 20px;
	display: none;
}

.rating-search__wrap.active .rating-search__result {
	display: block;
}

.rating-search__result-title {
	font-size: 20px;
	line-height: calc(48 / 40);
	font-weight: 600;
	margin-bottom: 4px;
	padding: 0 20px;
}

.rating-search__result-wrap::-webkit-scrollbar-thumb {
	background-color: #a3b0b8;
}
.rating-search__result-wrap::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rating-search__result-wrap::-webkit-scrollbar {
	width: 5px;
}

.rating-search__result-wrap {
	display: flex;
	flex-direction: column;
	margin-bottom: 18px;
	background-color: #fafafa;
	border: 1px solid #d1dadf;
	border-radius: 4px;
	padding: 14px 16px;
}

.rating-search__diagram {
	margin-top: 24px;
	position: relative;
}

.rating-search__result-rating-info {
	display: flex;
	margin-bottom: 25px;
}

.rating-search__result-rating {
	margin-right: 30px;
}

.rating-search__result-data-title {
	font-weight: 500;
	font-size: 12px;
	line-height: calc(12 / 10);
	color: #8c9195;
	text-transform: uppercase;
	margin-bottom: 8px;
}

.rating-search__result-data {
	font-weight: 600;
	font-size: 24px;
	line-height: calc(29 / 24);
}

.rating-search__result-rating-caption {
	font-size: 16px;
	line-height: calc(26 / 16);
	margin-bottom: 28px;
}

.rating-search__result-risk-item {
	display: flex;
	margin-bottom: 6px;
	align-items: flex-start;
}

.rating-search__result-risk-status {
	margin-right: 8px;
	width: 16px;
	height: 16px;
	position: relative;
	min-width: 16px;
}

.rating-search__result-risk-status svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.rating-search__result-risk-caption {
	font-size: 12px;
	line-height: calc(16 / 12);
}

.rating-search__another {
	font-size: 10px;
	color: #6b7880;
	line-height: calc(16 / 10);
	margin-top: 8px;
	cursor: pointer;
	transition-duration: 0.4s;
}

.rating-search__another.active {
	color: #6b7880;
}

.rating-search__result-event-block {
	margin-top: 28px;
}

.rating-search__result-event-info {
	font-size: 12px;
	line-height: calc(16 / 12);
	margin-bottom: 8px;
}

.rating-search__diagram {
	position: relative;
	--ratio: 296/401;
}

.rating-search__diagram::before {
	content: "";
	display: block;
	position: relative;
	height: 0;
	padding-bottom: calc(var(--ratio) * 100%);
}

.diagram-wrap {
	position: absolute;
	width: 53.4%;
	top: 56px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	--ratio: 1/1;
}

.diagram-wrap::before {
	content: "";
	display: block;
	position: relative;
	height: 0;
	padding-bottom: calc(var(--ratio) * 100%);
}

.diagram-back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.diagram-back path {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.diagram-data {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	mix-blend-mode: multiply;
}

.diagram-title {
	font-weight: 500;
	font-size: 12px;
	line-height: calc(12 / 10);
	text-transform: uppercase;
	transition-duration: 0.2s;
	cursor: pointer;
	z-index: 3;
	position: absolute;
}

.diagram-title--tax {
	top: -12%;
	left: 50%;
	transform: translateX(-50%);
}

.diagram-title--reliability {
	left: 87%;
	top: 13.3%;
}

.diagram-title--resources {
	right: 87%;
	top: 13.3%;
}

.diagram-title--stability {
	bottom: -1%;
	left: 68%;
}

.diagram-title--safety {
	bottom: -1%;
	right: 68%;
}

.rating-search__code-wrap {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}

.rating-search__code {
	margin-bottom: 4px;
	font-size: 12px;
	line-height: calc(26 / 16);
	color: #969696;
}

.rating-search__code:last-child {
	margin-bottom: 0;
}

.rating-search__characteristics-title {
	display: flex;
	align-items: center;
	padding: 8px 16px;
}

.rating-search__characteristics {
	--bs-gutter-x: 8px;
	--bs-gutter-y: 8px;
}

.rating-search__characteristics-item {
	border: 1px solid #d1dadf;
	border-radius: 4px;
	cursor: pointer;
}

.rating-search__characteristics-name {
	font-weight: 600;
	font-size: 14px;
	line-height: calc(12 / 10);
	color: #18222b;
	transition-duration: 0.4s;
	margin-right: 6px;
	text-align: left;
}

.rating-search__characteristics-value {
	font-weight: 500;
	font-size: 14px;
	line-height: calc(24 / 20);
}

.rating-search__characteristics-value span {
	color: #c8c8c8;
}

.rating-search__characteristics-body {
	display: none;
	padding-top: 0;
	padding-bottom: 16px;
	padding-left: 16px;
	padding-right: 40px;
	font-size: 12px;
	line-height: calc(16 / 12);
}

.rating-search__characteristics-body-title {
	text-transform: uppercase;
	color: #B5B5B5;
	font-weight: 500;
	margin-bottom: 8px;
	font-size: 12px;
}

.rating-search__characteristics-body ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

.rating-search__characteristics-body li {
	margin-bottom: 4px;
}

.rating-search__characteristics-item.active {
	display: block;
}

.rating-search__characteristics-title svg {
	margin-left: auto;
	transition-duration: 0.4s;
}

.rating-search__characteristics-item.active .rating-search__characteristics-title svg {
	transform: rotate(90deg);
}

.rating-search__diagram.active .diagram-title.active {
	opacity: 1;
}

.diagram-hover {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -54%);
	width: 110%;
	height: 110%;
	z-index: 3;
}

.diagram-hover__item {
	opacity: 0;
	transition-duration: 0.4s;
	cursor: pointer;
}

/* .diagram-hover__item.active {
	opacity: 0.5;
} */

.rating-search__another-body {
	display: none;
}

.rating-search__another-list.active .rating-search__another-body {
	display: block;
}

.diagram-text {
	display: none;
	position: absolute;
	width: 260px;
	padding: 12px 16px;
	background: #ffffff;
	border-radius: 2px;
	filter: drop-shadow(0px 3px 10px rgba(29, 36, 48, 0.08));
	z-index: 5;
}

.diagram-text__title {
	font-weight: 600;
	font-size: 16px;
	line-height: calc(20 / 16);
	margin-bottom: 8px;
}

.diagram-text__text {
	font-size: 12px;
	line-height: calc(16 / 12);
	margin-bottom: 16px;
}

.diagram-text.active {
	display: block;
}

.diagram-text ul {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

.diagram-text li {
	margin-bottom: 4px;
	font-size: 12px;
	line-height: calc(16 / 12);
}

.diagram-text li:last-child {
	margin-bottom: 0;
}

.diagram-text::before {
	content: "";
	width: 15px;
	height: 15px;
	background-color: #ffffff;
	transform: rotate(45deg);
	z-index: 1;
	position: absolute;
}

.diagram-hover__item.active {
	opacity: 0.5;
}

/* .diagram-text--0 {
	top: 33.6%;
	left: 79.6%;
}

.diagram-text--0::before {
	top: 13px;
	left: -5px;
} */

.rating-modal .diagram-text--0 {
	right: 30%;
	left: auto;
	top: 38%;
}

.rating-modal .diagram-text--0::before {
	left: auto;
	right: -5px;
}

.diagram-text--1 {
	bottom: 11%;
	right: 35%;
}

/* .rating-modal .diagram-text--0 {
	left: 65%;
} */

.rating-modal .diagram-text--1 {
	/* left: 65%; */
}

.diagram-text--1::before {
	bottom: 13px;
	right: -5px;
}

.diagram-text--2 {
	bottom: 20%;
	right: 68%;
}

.diagram-text--2::before {
	bottom: 13px;
	right: -5px;
}

.diagram-text--3 {
	top: 30%;
	right: 70%;
}

.diagram-text--3::before {
	top: 13px;
	right: -5px;
}

.diagram-text--4 {
	top: -60px;
	right: 53%;
}

.diagram-text--4::before {
	top: 100px;
	right: -5px;
}

.rating-search__search-reset {
	transition-duration: 0.4s;
	font-weight: 600;
	font-size: 14px;
	line-height: calc(18 / 14);
	text-align: center;
	border-radius: 4px;
	padding: 7px 31px;
	border: 1px solid #f1f1f1;
	background-color: #ffffff;
	margin-left: 20px;
	transition-duration: 0.4s;
	display: none;
	color: #18222b !important;
}

.rating-search__wrap.active .rating-search__search-reset {
	display: block;
}

.rating-search__result-info {
	padding: 0 20px 0;
}

@media (min-width: 992px) {
	.rating-search__characteristics-body-title {
		font-size: 10px;
	}

	.rating-search__result-info {
		border-right: 1px solid #d1dadf;
		/* height: 100%; */
		height: fit-content;
		min-height: 100%;
	}

	.rating-search {
		padding-top: 76px;
		padding-bottom: 46px;
	}

	.rating-search__wrap h2 {
		font-size: 40px;
	}

	.rating-search__wrap {
		padding: 50px 100px;
		background-color: #fafafa;
	}

	.rating-search__wrap h4 {
		margin-bottom: 0;
		margin-right: 19px;
		text-align: left;
	}

	.rating-search__search-wrap {
		flex-direction: row;
		justify-content: center;
	}

	.rating-search__wrap.active .rating-search__search-wrap {
		justify-content: unset;
	}

	.rating-search__wrap.active .rating-search__search {
		flex-grow: 1;
	}

	.rating-search__search-field {
	}

	.rating-search__search-field-wrap {
		width: 292px;
		max-width: 292px;
	}

	.rating-search__wrap.active .rating-search__search-field-wrap {
		flex-grow: 1;
		max-width: none;
		width: 100%;
	}

	.rating-search__result {
		margin-top: 53px;
	}

	.rating-search__result-title {
		font-size: 40px;
		margin-bottom: 4px;
		padding: 0;
		max-width: calc(100% - 16px);
	}

	.rating-search__result-wrap {
		flex-direction: row;
		padding: 0 !important;
	}

	.rating-search__diagram {
		margin-top: 0;
		padding-left: 40px;
		padding-right: 40px;
		width: 50%;
		max-width: 50%;
		border-top: none;
		/* border-left: 1px solid #d1dadf; */
		padding-top: 68px;
		padding-bottom: 28px;
	}

	.rating-search__result-info {
		padding-right: 40px;
		padding-left: 40px;
		width: 50%;
		max-width: 50%;
		padding-top: 28px;
		padding-bottom: 28px;
	}

	.diagram-wrap {
		top: 68px;
	}

	.diagram-title {
		font-size: 10px;
		font-weight: 600;
	}

	.diagram-title--tax {
		top: -8.37%;
		left: 50%;
		transform: translateX(-50%);
	}

	.diagram-title--reliability {
		left: 101.67%;
		top: 31.38%;
	}

	.diagram-title--resources {
		right: 101.67%;
		top: 31.38%;
	}

	.diagram-title--stability {
		bottom: -2.1%;
		left: 63.18%;
	}

	.diagram-title--safety {
		bottom: -2.1%;
		right: 63.18%;
	}

	.rating-search__code-wrap {
		flex-direction: row;
		margin-bottom: 24px;
	}

	.rating-search__code {
		margin-bottom: 0;
		margin-right: 20px;
		font-size: 16px;
	}

	.rating-search__code:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}

	.rating-search__characteristics-title {
		padding: 8px !important;
		flex-direction: column;
	}

	.rating-search__characteristics-name {
		font-size: 10px;
		margin-right: 0;
		margin-bottom: 6px;
		text-align: center;
		text-transform: uppercase;
	}

	.rating-search__characteristics-value {
		font-weight: 600;
		font-size: 20px;
	}

	.rating-search__characteristics-item {
		box-shadow: 0px 3px 10px rgba(29, 36, 48, 0.08);
		transition-duration: 0.2s;
	}

	.rating-search__characteristics-title svg {
		display: none;
	}

	.rating-search__diagram.active .rating-search__characteristics-item.active {
		opacity: 1;
	}

	.rating-search__diagram.active .rating-search__characteristics-item {
		opacity: 0.4;
	}

	.rating-search__another {
		font-size: 10px;
		margin-top: 4px;
	}
}

.diagram-text__close {
	display: none;
}

@media (max-width: 991.9px) {
	.rating-search__search {
		flex-direction: column;
		align-items: center;
	}

	.diagram-hover__item.active {
		opacity: 0 !important;
	}

	.rating-search__diagram.active .diagram-title {
		opacity: 1;
	}

	.rating-search__search-btn {
		margin-left: 0;
		margin-top: 16px;
		width: 100%;
		background-color: #18222b !important;
		margin-bottom: 0;
	}

	.rating-search__wrap.active .rating-search__search-btn {
		margin-bottom: 40px;
	}

	.rating-search__search-reset {
		width: 100%;
		margin-left: 0;
		margin-top: 16px;
	}

	.rating-search__result-wrap {
		padding: 0;
		border: none;
	}

	.rating-search__diagram {
		--ratio: 260/335;
		padding: 0 20px;
	}

	.rating-search__diagram::after {
		content: "";
		position: absolute;
		top: 0;
		left: -20px;
		width: calc(100% + 40px);
		height: 1px;
		background-color: #d1dadf;
	}

	.diagram-wrap {
		min-width: 135px;
	}

	.rating-search__result-wrap {
		padding-top: 20px;
		position: relative;
	}

	.rating-search__result-wrap::before {
		content: "";
		position: absolute;
		top: 0;
		left: -20px;
		width: calc(100% + 40px);
		height: 1px;
		background-color: #d1dadf;
	}

	.rating-search__result-rating-info {
		flex-direction: column;
		margin-bottom: 32px;
	}

	.rating-search__result-rating {
		margin-right: 0;
		/* margin-bottom: 24px; */
	}

	.rating-search__characteristics-item {
		background-color: transparent;
		transition-duration: 0.4s;
	}

	.rating-search__characteristics-item.active {
		box-shadow: 0px 5px 10px rgba(29, 36, 48, 0.15);
		background-color: #ffffff;
	}

	.rating-search__characteristics-item.active .rating-search__characteristics-body {
		display: block !important;
	}

	.rating-modal .diagram-text {
		right: unset;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: fit-content;
		/* position: relative; */
	}

	.diagram-text::before {
		display: none;
	}

	.diagram-text__title {
		position: relative;
	}

	.diagram-text__close {
		display: block;
		background-color: transparent;
		border: none;
		padding: 0;
		position: absolute;
		right: -4px;
		top: -7px;
	}

	.diagram-text__close svg {
		width: 8px;
		height: 8px;
	}
}

@media (min-width: 767.9px) and (max-width: 991.9px) {
	.diagram-title--tax {
		top: -8%;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media (max-width: 575.9px) {
	.rating-search__diagram {
		--ratio: 290/335;
	}
}

@media (max-width: 400px) {
	.rating-search__characteristics-name,
	.rating-search__characteristics-value {
		font-size: 11px;
	}

	.diagram-title {
		font-size: 10px;
	}
}

.rating-search__preloader {
	min-height: 200px;
	position: relative;
	--ratio: 1/2;
}

.rating-search__preloader::before {
	content: "";
	display: block;
	position: relative;
	height: 0;
	padding-bottom: calc(var(--ratio) * 100%);
}

@media (max-width: 600px) {
	.diagram-title--tax {
		top: -14%;
	}

	.diagram-title--reliability {
		left: 88%;
		top: 11%;
	}

	.diagram-title--stability {
		left: 68%;
		bottom: -5%;
	}

	.diagram-title--safety {
		bottom: -5%;
		right: 68%;
	}

	.diagram-title--resources {
		right: 88%;
		top: 11%;
	}
}

@media (max-width: 400px) {
	.rating-search .rating-search__diagram {
		--ratio: 335/335;
	}

	.rating-search__characteristics-name {
		font-size: 12px;
	}

	.rating-search__characteristics-title {
		padding: 8px 14px;
	}
}

@media (max-width: 360px) {
	.rating-modal .diagram-text {
		left: calc(50% + 10px);
	}
}

@media (max-width: 350px) {
	.rating-search .rating-search__diagram {
		--ratio: 360/335;
	}

	.rating-search__characteristics-name {
		font-size: 10px;
		margin-right: 4px;
	}

	.rating-search__characteristics-title {
		padding: 8px 11px;
	}

	.rating-search__characteristics-value {
		font-size: 10px;
	}

	.rating-search__characteristics-title svg {
		width: 15px;
		height: 15px;
	}
}

@media (max-width: 1199.9px) and (min-width: 992px) {
	.rating-modal .diagram-text--0 {
		right: 30%;
		left: auto;
		top: 38%;
	}

	.rating-modal .diagram-text--0::before {
		left: auto;
		right: -5px;
	}

	.rating-modal .diagram-text--1 {
		left: auto;
		right: 30%;
	}

	.rating-modal .diagram-text--1::before {
		left: auto;
		right: -5px;
	}
}
