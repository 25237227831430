body {
	margin: 0;
	font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--body-color: #18222b;
	--darken-blue-color: #2D5979;
	--grey-color: #A3B0B8;
	--darken-white-color: #F1F1F1;
	--white-blue-color: #D1DADF;
	color: var(--body-color);
}

#root > * {
	/*overflow: hidden;*/
}


body {
	overflow: auto;
}

body.fixed {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
}

body.fixed::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 900;
	background-color: rgba(24, 34, 43, 0.6);
}

body.no-scroll {
	overflow: hidden;
}

.App {
	/*overflow: hidden;*/
}


a {
	text-decoration: none;
}

.section-title {
	font-size: 16px;
	line-height: calc(24/16);
	font-weight: 600;
	text-transform: uppercase;
	color: #d50831;
}

@media (max-width:991.9px) {
	.section-title {
		margin-bottom: 12px !important;
		font-weight: 500;
		font-size: 14px;
	}
}

@media (min-width: 1400px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1180px !important;
	}
}

.captcha {
	margin-top: 16px;
}

@media (min-width: 576px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.scrollbar-horizontal::-webkit-scrollbar {
	height: 4px;
}

.scrollbar-horizontal::-webkit-scrollbar-track {
	background-color: #d9d9d9;
}

.scrollbar-horizontal::-webkit-scrollbar-thumb {
	background-color: #18222b;
	/* цвет плашки */
	border-radius: 10px;
	/* закругления плашки */
}

.scrollbar-vertical::-webkit-scrollbar {
	width: 7px;
}

.scrollbar-vertical::-webkit-scrollbar-track {
	background-color: #d9d9d9;
}

.scrollbar-vertical::-webkit-scrollbar-thumb {
	background-color: #18222b;
	/* цвет плашки */
	border-radius: 16px;
	/* закругления плашки */
}

.disable {
	pointer-events: none;
	opacity: 0.5;
}

.bg-color-darken-blue {
	background-color: var(--darken-blue-color);
}

.bg-color-body {
	background-color: var(--body-color);
}

.bg-color-transparent {
	background-color: transparent;
}


@media (max-width: 991.9px) {
	.sm-bg-color-darken-blue {
		background-color: var(--darken-blue-color);
	}

	.sm-bg-color-body {
		background-color: var(--body-color);
	}

	.sm-bg-color-transparent {
		background-color: transparent;
	}
}
