.services {
	background-color: #d1dadf;
	padding: 80px 0 240px 0;
}

.services__text-info {
	max-width: 779px;
	margin-bottom: 80px;
}

.services__header {
	font-size: 38px;
	font-weight: 600;
	line-height: 50px;
	margin-bottom: 20px;
	color: #182e42;
}

.services__text {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 0;
}

.services__slider-wrap {
	width: 100%;
}

@media (max-width: 991.9px) {
	.services {
		padding: 40px 0;
	}

	.services__header {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 12px;
	}

	.services__text {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	.services__text-info {
		margin-bottom: 40px;
	}
}
