.testing-registration {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.testing-content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

@media (min-width: 992px) {
	.testing-content__info {
		flex-grow: 1;
	}
}

@media (max-width: 991.9px) {
	.testing-content__info {
		flex-direction: column;
		flex-grow: 1;
	}

	.testing-content__right-block {
		flex-grow: 1;
	}
}