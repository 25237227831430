.rating-table {
	/* padding-bottom: 20px; */
	padding-top: 40px;
	background: #f1f1f1;
}

.rating-table__notification {
	font-size: 18px;
	font-weight: 500;
	padding: 10px 14px;
}

.rating-table__text-wrap {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 80px;
}

.rating-table__text {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 16px;
	max-width: 780px;
}

.rating-table__under-diagram-rigth {
	color: #626f77;
	font-size: 14px;
	line-height: calc(22 / 14);
	max-width: 280px;
	margin-left: 64px;
	min-width: 280px;
}

.rating-table__under-diagram-rigth a {
	text-decoration: underline;
	color: inherit;
}

.rating-table__header {
	font-weight: 600;
	font-size: 28px;
	line-height: 35px;
	margin-bottom: 42px;
	max-width: 780px;
}

.rating-table h2 {
	font-weight: 600;
	margin-bottom: 24px;
	font-size: 22px;
	line-height: 140%;
	display: block;
}

.rating-table__title {
	display: block;
}

.rating-table .section-title {
	margin-bottom: 16px;
}

.rating-table__sort-wrap {
	display: flex;
	overflow: hidden;
	min-width: 640px;
}

.rating-table__btn {
	/* border: none; */
	transition-duration: 0.4s;
	background-color: transparent;
	padding: 8px 16px;
	font-size: 12px;
	line-height: calc(22 / 14);
	font-weight: 500;
	border: 1px solid #a3b0b8;
	border-radius: 4px;
	color: #0f232e;
	display: inline-flex;
	gap: 0.5rem;
	align-items: center;
}

.rating-table__dropdown {
	cursor: pointer;
}

.rating-table__dropdown_offset {
	padding-bottom: 4px;
}

/* .rating-table__btn:first-child {
	border-radius: 4px 0 0 4px;
	border-left: 1px solid #a3b0b8;
}

.rating-table__btn:last-child {
	border-radius: 0px 4px 4px 0px;
	border-right: 1px solid #a3b0b8;
} */

.rating-table__btn.active,
.rating-table__btn:hover,
.rating-table__btn:focus,
.rating-table__btn:active {
	background-color: #a3b0b8;
	color: inherit;
}

.rating-table__table-wrap {
	border-radius: 4px;
	overflow: hidden;
	width: 100%;
}

.rating-table__table {
	width: 100%;
	overflow-x: auto;
}

.rating-table__table-head {
	border-radius: 0 0 4px 4px;
	display: flex;
	background-color: #fafafa;
	margin-bottom: 4px;
}

.rating-table__wrap {
	/* padding-bottom: 4px; */
}

.rating-table__th {
	color: #808a90;
	line-height: calc(22 / 14);
	font-weight: 400;
	font-size: 12px;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 6px;
	padding-bottom: 6px;
	transition-duration: 0.2s;
}

.rating-table__th.active {
	color: #0f232e;
}

.rating-table__th--1 {
	width: 35px;
	min-width: 35px;
}

.rating-table__th--2 {
	width: auto;
	max-width: calc(100% - 153px);
	flex-grow: 1;
}

.rating-table__th--3 {
	width: 200px;
	min-width: 200px;
}

.rating-table__th--4 {
	width: 78px;
	min-width: 78px;
	text-align: center;
}

.rating-table__td {
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 14px;
	line-height: calc(20 / 18);
	font-weight: 400;
	transition-duration: 0.2s;
}

.rating-table__td div {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	line-height: calc(22 / 14);
}

.rating-table__td span {
	line-height: calc(22 / 14);
}

.rating-table__td--1,
.rating-table__td--4 {
	display: flex;
	justify-content: center;
}

.rating-table__td.active {
	font-weight: 500;
	font-size: 14px;
}

.rating-table__td--1 {
	width: 35px;
	min-width: 35px;
	font-weight: 500;
	color: #a3b0b8;
	font-size: 12px;
	line-height: calc(20 / 18);
}

.rating-table__td--2 {
	width: auto;
	max-width: calc(100% - 153px);
	font-weight: 600;
	flex-grow: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.rating-table__td--3 {
	font-size: 18px;
	width: 200px;
	min-width: 200px;
	color: #6b7880;
}

.rating-table__td--4 {
	width: 78px;
	min-width: 78px;
	text-align: center;
	font-weight: 600;
}

.rating-table__td--5,
.rating-table__td--6,
.rating-table__td--8 {
	width: 93px;
	min-width: 93px;
	text-align: center;
}

.rating-table__rating-top {
	color: #d50831;
}

.rating-table__rating-middle {
	color: #1d364c;
}

.rating-table__table-tr {
	display: flex;
	margin-bottom: 4px;
	background-color: #ffffff;
	box-shadow: 0px 3px 10px rgba(29, 36, 48, 0.08);
	border-radius: 4px;
}

.rating-table__table-tr:last-child {
	border-bottom: none;
}

.rating-table__head-wrap {
	margin-bottom: 18px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.rating-table__type {
	margin-bottom: 32px;
	font-weight: 500;
	font-size: 14px;
	line-height: calc(22 / 14);
	white-space: nowrap;
	width: 100%;
	padding: 8px 16px;
	border: 1px solid #a3b0b8;
	border-radius: 4px;
}

/* .rating-table__void, */
.rating-table__td-btn {
	display: flex;
	align-items: center;
	width: 117px;
}

.rating-table__td-btn-modal {
	width: 97px;
	background-color: #f1f1f1;
	border: none;
	border-radius: 4px;
	transition-duration: 0.4s;
	text-align: center;
	font-size: 14px;
	line-height: calc(22 / 14);
	color: #2b3843;
	height: 32px;
	padding: 4px 10px !important;
}

.rating-table__td-btn-modal svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
}

.rating-table__td-btn-modal:hover,
.rating-table__td-btn-modal:active,
.rating-table__td-btn-modal:focus {
	background-color: #d4d0d0;
}

.rating-table__td-btn-modal {
	padding: 4px;
	position: relative;
}

.rating-table__mob-inn {
	margin-top: 2px;
	font-size: 12px;
	line-height: calc(16 / 12) !important;
	font-weight: 400;
	color: #6b7880;
}

.rating-table__search {
	padding: 24px;
	background-color: #fafafa;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px 4px 0px 0px;
	margin-bottom: 4px;
}

.rating-table__search-wrap {
	display: flex;
	justify-content: space-between;
	/* flex-direction: column; */
	width: 100%;
	gap: 0.5rem;
}

.rating-table__search-btn {
	width: 100%;
	border: none;
	/* background-color: #d50831; */
	background-color: #d1dadf;
	color: #2b3843;
	border-radius: 4px;
	padding: 8px 23px;
	font-weight: 600;
	font-size: 14px;
	line-height: calc(22 / 14);
	/* color: #ffffff; */
	transition-duration: 0.4s;
}

/* .rating-table__search-btn:hover,
.rating-table__search-btn:focus,
.rating-table__search-btn:active {
	background-color: #a70a29;
} */

.rating-table__search-btn.disabled {
	background-color: #d1dadf;
	color: #2b3843;
}

.rating-table__search-input-wrap {
	display: flex;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 12px;
	position: relative;
	margin-bottom: 8px;
}

.rating-table__search-input-wrap::after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	height: 2px;
	background-color: #a3b0b8;
	border-radius: 1px;
}

.rating-table__not-found {
	background-color: #ffffff;
	box-shadow: 0px 3px 10px rgba(29, 36, 48, 0.08);
	border-radius: 4px;
	padding: 12px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.rating-table__not-found-title {
	text-align: center;
	font-weight: 500;
	line-height: calc(24 / 18);
	font-size: 14px;
	color: #18222b;
}

.rating-table__not-found-caption {
	line-height: calc(22 / 14);
	font-size: 12px;
	color: #6b7880;
}

.rating-table__tab-wrap {
	padding: 40px 0;
}

.rating-table__bottom-buttons {
	display: flex;
	justify-content: center;
	margin-top: 16px;
}

.rating-table__up,
.rating-table__count {
	border: none;
	outline: none;
	background-color: #ffffff;
	padding: 12px 16px;
	border-radius: 4px;
	display: block;
}

.rating-table__count {
	margin-left: 16px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	color: #a3b0b8;
}

.rating-table__count span {
	color: #18222b;
}

.rating-table__right-buttons {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	display: flex;
}

.rating-table__subitems {
	color: #a3b0b8;
	font-size: 14px;
}

@media (min-width: 1200px) {
	.rating-table__btn {
		padding: 8px 16px;
		font-size: 16px;
	}
}

@media (min-width: 992px) {
	.rating-table__search {
		padding: 1rem 24px 1rem 90px;
	}

	.rating-table__not-found {
		padding: 22px 20px;
	}

	.rating-table__not-found-title {
		font-size: 18px;
	}

	.rating-table__not-found-caption {
		font-size: 14px;
	}

	.rating-table__search-wrap {
		flex-direction: row;
		align-items: center;
	}

	.rating-table__search-btn {
		width: min-content;
	}

	.rating-table__mob-inn {
		display: none;
	}

	.rating-table__search-input-wrap {
		margin-right: 20px;
		margin-bottom: 0;
		width: 340px;
	}

	.rating-table__td-btn-modal svg {
		display: none;
	}

	.rating-table__td-btn {
		width: 117px;
	}

	.rating-table__td-btn-modal {
		padding: 4px 16px;
		height: 30px;
	}

	.rating-table {
		padding-top: 240px;
		padding-bottom: 0px;
	}

	.rating-table__th--1,
	.rating-table__td--1 {
		text-align: center;
		width: 80px;
		min-width: 80px;
	}

	.rating-table__th--9,
	.rating-table__td--9 {
		display: block;
	}

	.rating-table h2 {
		font-weight: 600;
		font-size: 40px;
		line-height: calc(44 / 40);
		margin-bottom: 56px;
		display: inline-flex;
		gap: 16px;
	}

	.rating-table__sort-wrap {
		max-width: 100%;
		width: auto;
		min-width: auto;
	}

	.rating-table__btn {
		padding: 8px 16px;
		font-size: 14px;
		line-height: calc(22 / 14);
	}

	.rating-table__table {
	}

	.rating-table__th {
		font-size: 14px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.rating-table__th--2 {
		width: auto;
		max-width: calc(100% - 400px);
		flex-grow: 1;
	}

	.rating-table__th--3 {
		width: 200px;
		min-width: 200px;
	}

	.rating-table__th--4 {
		width: 120px;
		min-width: 120px;
		text-align: center;
	}

	.rating-table__td {
		padding-top: 18px;
		padding-bottom: 18px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 14px;
		line-height: calc(20 / 18);
	}

	.rating-table__td.active {
		font-size: 18px;
	}

	.rating-table__td--1 {
		font-size: 18px;
	}

	.rating-table__td--2 {
		flex-grow: 1;
		max-width: calc(100% - 400px);
		width: auto;
	}

	.rating-table__td--4 {
		width: 120px;
		min-width: 120px;
	}

	.rating-table__td--2,
	.rating-table__td--4 {
		font-size: 18px;
		line-height: calc(24 / 18);
		font-weight: 500;
	}

	.rating-table__td--6,
	.rating-table__td--7,
	.rating-table__td--8 {
		text-align: center;
		width: 150px;
		min-width: 150px;
	}

	.rating-table__td--5 {
		text-align: center;
		width: 152px;
		min-width: 152px;
	}

	.rating-table__head-wrap {
		margin-bottom: 48px;
		flex-direction: row;
		align-items: center;
		min-width: 1100px;
	}

	.rating-table__type {
		margin-bottom: 0;
		margin-right: 74px;
		padding: 8px 24px;
		width: auto;
	}

	.rating-table__table-wrap {
	}

	.rating-table__bottom-buttons {
		height: 46px;
		position: relative;
	}
}

.show-more {
	text-align: center;
	padding: 8px;
	background-color: #d50831;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	line-height: calc(22 / 14);
	border: none;
	width: 328px;
	color: #ffffff !important;
	transition-duration: 0.4s;
}

.show-more:hover {
	background-color: #a70a29;
}

@media (max-width: 991.9px) {
	.rating-table__th--3,
	.rating-table__td--3 {
		display: none;
	}

	.rating-table__void,
	.rating-table__td-btn {
		width: 40px;
		padding-left: 0;
		padding-right: 8px;
	}

	.rating-table__table-tr {
		cursor: pointer;
	}

	.rating-table__right-buttons {
		display: none;
	}

	.show-more {
		width: 100%;
	}

	.rating-table__btn span {
		display: none;
	}

	.rating-table__btn {
		padding: 12px;
		margin-left: 32px;
	}

	.rating-table__form {
		flex-grow: 1;
	}

	.rating-table__title {
		margin-bottom: 12px;
	}

	.rating-table__text-wrap {
		flex-direction: column;
		justify-content: unset;
		margin-bottom: 40px;
	}

	.rating-table__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 12px;
	}

	.rating-table__under-diagram-rigth {
		margin-left: 0;
		width: auto;
		max-width: none;
		min-width: unset;
	}

	.rating-table__header {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 12px;
	}

	.rating-table__notification {
		text-align: center;
	}

	.rating-table__notification span {
		font-weight: 700;
	}
}

@media (max-width: 400px) {
	.rating-table__btn {
		margin-left: 0;
	}
}

@media (max-width: 360px) {
	.rating-table__td {
		font-size: 11px;
	}

	.rating-table__td--2,
	.rating-table__th--2 {
		max-width: calc(100% - 115px);
	}
}

@media (max-width: 767.9px) {
	.rating-table__tabs {
		height: 41px;
		position: relative;
	}
}
