.select__container {
    display: inline-flex;
    padding: 4px 16px;
    background: #fff;
    position: relative;
    border-radius: 4px; 
}

.select__value {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.select_open {
    rotate: 180deg;
}

.select__list {
    position: absolute;
    bottom: 0;
    left: 0;
    translate: 0 100%;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    border-radius: 4px; 
    box-shadow: 0px 3px 10px 0px rgba(29, 36, 48, 0.08); 
    overflow: hidden;
    z-index: 11;
}

.select__element {
    padding: 4px 16px;
    cursor: pointer;
}


.select__element:hover {
    background: #D1DADF;
}
