.info-slider ul {
	margin-bottom: 0;
	padding-left: 20px;
}

.slider-wrap {
	position: relative;
}

.swiper {
	position: static;
}

.swiper-slide {
	height: min-content;
	background-color: transparent;
	border-top: 6px solid #182e42;
	border-radius: 4px;
	height: auto;
}

.info-slider__wrap {
	display: block;
	padding: 28px 28px 20px;
	transition-duration: 0.4s;
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: #ffffff;
}

.info-slider__wrap,
.info-slider__wrap:hover {
	color: inherit;
}

.swiper-slide {
	transition-duration: 0.4s;
	overflow: hidden;
}

.info-slider__sub-header {
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 16px;
	color: #d50831;
	text-transform: uppercase;
}

.info-slider__header {
	font-size: 33px;
	font-weight: 600;
	line-height: 36px;
	margin-bottom: 28px;
}

.info-slider__text {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.info-slider__link,
.info-slider__link:hover {
	display: block;
	margin-top: auto;
	padding: 12px 16px 11px;
	width: fit-content;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	text-transform: uppercase;
	color: inherit;
}

/* .info-slider__link:hover {
	text-decoration: underline;
} */

.info-slider__link-wrap {
	border-bottom: 1px solid transparent;
	transition-duration: 0.4s;
}

.info-slider__wrap:hover .info-slider__link-wrap {
	border-color: #18222b;
}

.info-slider__wrap:hover .info-slider__link-wrap.black {
	border-color: #ffffff;
}

.info-slider__link svg {
	width: 20px;
	height: 20px;
	margin-left: 8px;
}

.info-slider__text:last-child {
	margin-bottom: 0;
}

.info-slider__wrap.last-slide {
	background-image: url("/public/img/bus.png");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #ffffff;
	position: relative;
	padding: 0;
	width: 100%;
	height: 100%;
}

.info-slider__wrap.last-slide .info-slider__sub-header {
	color: #ffffff;
}

.info-slider__shadow {
	position: absolute;
	display: flex;
	flex-direction: column;
	padding: 34px 28px 20px;
	width: 100%;
	height: 100%;
	background-color: rgba(24, 34, 43, 0.7);
}

.swiper-slide:last-child {
	border-top: none;
}

@media (min-width: 992px) {
	.swiper-slide {
		width: calc((100% - 40px) / 3) !important;
		height: 504px;
	}

	.swiper {
		/* width: 33.333% !important; */
		margin: 0;
		/* padding-bottom: 85px; */
	}
}

.swiper-pagination-bullets {
	bottom: -5px !important;
}

.swiper-pagination {
	width: 100% !important;
	left: 0 !important;
	/* padding: 0 300px; */
	text-align: center;
}

.swiper-pagination-bullet {
	height: 2px;
	border-radius: 10px;
	/* margin-left: 2px !important;
	margin-right: 2px !important; */
	/* width: calc((100% - 20px) / 5);
	max-width: calc((100% - 20px) / 5); */
	width: 100px;
	height: 5px;
	background-color: #d9d9d9;
	opacity: 1;
}

.swiper-pagination-bullet:not(:last-child) {
	margin: 0 20px 0 0 !important;
}

.swiper-pagination-bullet-active {
	background-color: #d50831;
}

@media (max-width: 1199.9px) {
	.info-slider__link {
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 22px !important;
		padding: 12px 0 !important;
	}

	.long-button {
		width: 261px;
	}
}

@media (max-width: 991.9px) {
	.info-slider {
		width: 278px !important;
		height: auto;
		margin: 0 !important;
	}

	.swiper-slide {
		height: auto;
	}

	.info-slider__wrap {
		padding: 16px 20px;
		height: 100%;
	}

	.info-slider__header {
		margin-bottom: 8px;
	}

	.swiper-pagination {
		display: none;
	}

	.swiper-slide {
		width: 100% !important;
		height: 399px;
	}

	.swiper {
		width: 306px !important;
		overflow: visible;
	}

	.info-slider__sub-header {
		margin-bottom: 12px;
	}

	.info-slider__header {
		font-size: 24px;
		font-weight: 600;
		line-height: 26px;
		margin-bottom: 24px;
	}

	.info-slider__text {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}
}

@media (max-width: 360px) {
	.info-slider__header {
		font-size: 16px;
	}
	.info-slider__text {
		font-size: 12px;
	}
	.info-slider__wrap {
		padding: 12px 16px;
	}
	.info-slider {
		width: 230px !important;
	}
}
