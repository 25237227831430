.footer {
	background-color: #18222b;
	padding-top: 40px;
	padding-bottom: 40px;
}

.footer__top {
	position: relative;
}

.footer__wrap {
	background: #f1f1f1;
}

.footer__top-wrap {
	display: flex;
	flex-direction: column;
}

.footer__logo {
	position: relative;
	padding-top: 16px;
	padding-left: 40px;
	z-index: 1;
}

.footer__logo-main {
	font-weight: 600;
	font-size: 24px;
	line-height: calc(33 / 24);
	color: #e1e1e1;
	width: 214px;
	margin-bottom: 8px;
}

.footer__logo-second {
	font-weight: 600;
	font-size: 12px;
	line-height: calc(16 / 12);
	color: #e1e1e1;
	width: 230px;
}

.footer__logo-pic {
	position: absolute;
	width: 49.49px;
	height: 43.17px;
	left: 0px;
	top: 0;
}

.footer__logo-pic svg {
	width: 100%;
}

.footer__top-caption {
	font-weight: 400;
	font-size: 14px;
	line-height: calc(20 / 14);
	color: #919598;
	max-width: 576px;
	margin-bottom: 40px;
	padding-left: 40px;
}

.footer__contacts {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
}

.footer__contacts-title {
	color: #7d8287;
	font-size: 14px;
	line-height: calc(22 / 14);
	margin-bottom: 16px;
}

.footer__contacts-item {
	margin-bottom: 15px;
	width: fit-content;
	transition-duration: 0.4s;
	border-bottom: 1px solid transparent;
}

.footer__contacts-item:hover {
	border-color: #f1f1f1;
}

.footer__contacts-item:last-child {
	margin-bottom: 28px;
}

.footer__contacts-item a {
	font-weight: 600;
	color: #e1e1e1;
	font-size: 14px;
	line-height: calc(20 / 14);
}

.footer__rating {
	padding: 40px 20px 0;
}

.footer__rating-title {
	font-size: 16px;
	line-height: calc(40 / 20);
	margin-bottom: 2px;
	font-weight: 600;
	color: #f1f1f1;
}

.footer__rating-caption {
	font-size: 14px;
	line-height: 140%;
	color: #f1f1f1;
}

.footer__rating-btn {
	background-color: #d1dadf;
	transition-duration: 0.4s;
	text-align: center;
	width: 100%;
	padding: 8px 12px;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	line-height: calc(18 / 14);
	border: none;
}

.footer__rating-btn:hover,
.footer__rating-btn:focus,
.footer__rating-btn:active {
	background-color: #bcc1c4;
}

.footer__top-gradient {
	position: absolute;
	width: 228px;
	height: 185px;
	bottom: 74px;
	left: calc((100vw - 100%) * (-0.5) - 6px);
	z-index: 0;
}

.footer__top-gradient .gradient1 {
	position: absolute;
	top: 4px;
	left: -8px;
	width: 283px;
	height: 181px;
	filter: blur(77px);
	background: rgba(213, 8, 49, 0.8);
	z-index: 2;
}

.footer__top-gradient .gradient2 {
	position: absolute;
	top: 55px;
	left: 80px;
	width: 180px;
	height: 180px;
	filter: blur(77px);
	background: #28acd6;
	z-index: 1;
}

.footer__center {
	justify-content: space-between;
	position: relative;
	z-index: 1;
	padding: 40px 0px;
}

.footer__center-menu {
	display: flex;
}

.footer__center-menu-item {
	position: relative;
	padding-right: 36px;
	white-space: nowrap;
}

.footer__center-menu-item::after {
	content: "";
	position: absolute;
	top: 50%;
	height: 4px;
	width: 4px;
	right: 16px;
	border-radius: 50%;
	background-color: #40484f;
	transform: translateY(-50%);
}

.footer__center-menu-item:last-child {
	padding-right: 0;
}

.footer__center-menu-item:last-child::after {
	display: none;
}

.footer__center-menu-item a {
	font-weight: 500;
	font-size: 14px;
	line-height: calc(17 / 14);
	color: #e1e1e1;
}

.footer__bottom {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer__bottom-caption {
	font-size: 12px;
	line-height: calc(20 / 12);
	color: #919598;
	margin-bottom: 46px;
}

.footer__bottom-caption a {
	color: #e1e1e1;
}

.footer__address,
.footer__policy {
	margin-bottom: 4px;
}

.footer__policy-link {
	transition-duration: 0.4s;
	border-bottom: 1px solid transparent;
}

.footer__policy-link:hover {
	border-bottom: 1px solid #919598;
}

.footer__inn,
.footer__copyright {
	margin-bottom: 0;
}

.footer__copyright-and-policy,
.footer__inn-and-address {
	font-size: 12px;
	line-height: calc(20 / 12);
	color: #919598;
}

.footer__rating {
	background: #d1dadf;
	border-radius: 4px;
}

.footer__top-block {
	background: #f1f1f1;
}

.footer__rating-header {
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 8px;
	max-width: 480px;
}

.footer__rating-text {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 20px;
}

.footer__telegram {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: #fbfbfb;
	padding: 16px 24px;
	width: 100%;
	display: block;
	text-align: center;
	background: #d50831;
	border-radius: 4px;
	margin-bottom: 80px;
	transition-duration: 0.4s;
}

.footer__telegram:hover {
	color: #fbfbfb;
}

.footer__rating-phone-img {
	text-align: center;
}

.footer__top-links {
	display: flex;
	flex-wrap: wrap;
	width: 310px;
	max-width: 310px;
}

.footer__top-links a {
	color: #f1f1f1 !important;
	font-weight: 600;
	font-size: 14px;
	line-height: calc(20 / 14);
	margin-left: 17px;
	border-bottom: 1px solid transparent;
	transition-duration: 0.4s;
}

.footer__top-links a:hover {
	border-color: #f1f1f1;
}

.footer__rating-phone-img img {
	max-width: 100% !important;
}

.footer__form {
	width: 100%;
	left: 50%;
	top: 0;
	padding: 28px 28px 40px !important;
	background-color: #2d5979;
	border-radius: 4px;
}

.footer__form-wrap {
	display: flex;
	justify-content: space-between;
}

.footer__form-info {
	width: calc(50% - 10px);
	color: #ffffff;
}

.footer__form-fields {
	width: calc(50% - 10px);
	min-width: calc(50% - 10px);
	margin-left: 60px;
}

.footer__form-header {
	font-size: 33px;
	font-weight: 600;
	line-height: 36px;
	margin-bottom: 16px;
}

.footer__form-text {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 0;
}

.footer__form-fields-inputs {
	display: flex;
}

.footer__form-fields-input.name {
	width: calc(213 / 552 * 100%);
	margin-right: 20px;
}

.footer__form-fields-input.email {
	width: calc(319 / 552 * 100%);
}

.footer__wrap .form-field__caption {
	color: #f1f1f1;
}

.footer__form-fields-input.textarea .form-field__input {
	height: 80px;
}

.footer__form-fields-input.textarea {
	margin: 20px 0;
}

.footer__form-button {
	border: none;
	background-color: #d50831;
	border-radius: 4px;
	padding: 12px 16px;
	font-weight: 600;
	font-size: 16px;
	line-height: calc(22 / 16);
	color: #ffffff;
	transition-duration: 0.4s;
}

.footer__form-button:hover {
	background-color: #a70a29;
}

.footer__form-sent {
	height: 232px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ffffff;
}

.footer__policy-link,
.footer__policy-link:hover {
	color: inherit;
	text-decoration: none;
}

.footer__form-sent-header {
	font-size: 33px;
	font-weight: 600;
	line-height: 36px;
	margin-bottom: 8px;
}

.footer__form-sent-text {
	font-size: 18px;
	font-weight: 600;
	line-height: 36px;
	margin-bottom: 20px;
}

.footer__form-sent-button {
	border: none;
	outline: none;
	background-color: #182e42;
	border-radius: 4px;
	padding: 12px 16px;
	color: inherit;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

@media (max-width: 390px) {
}

@media (max-width: 575px) {
	.footer__top::after,
	.footer__center::after {
		left: 8px;
		width: calc(100% - 16px);
	}
}

@media (max-width: 991.9px) {
	.footer__top-block {
		padding: 0 !important;
	}

	.footer__contacts-item a {
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
	}

	.footer__contacts-item:last-child {
		margin-bottom: 0 !important;
	}

	.footer__center-menu {
		display: none;
	}

	.footer__logo-main {
		font-weight: 600;
		font-size: 14px;
		line-height: normal;
		margin-bottom: 5px;
		width: min-content;
	}

	.footer__logo {
		padding-top: 1px;
	}

	.footer__logo-pic svg,
	.footer__logo-pic {
		width: 28px;
		height: 28px;
	}

	.footer__logo-second {
		font-size: 7px;
	}

	.footer__top-caption {
		font-size: 12px;
		line-height: 16px;
	}

	.footer__top {
		padding: 0 8px;
	}

	.footer__rating-header {
		max-width: unset;
	}
}

@media (min-width: 1400px) {
	.footer__top {
		padding-left: 100px !important;
		padding-right: 0px !important;
	}

	.footer__rating-text,
	.footer__rating-header {
		max-width: 580px !important;
	}

}

@media (min-width: 992px) {
	.footer__top-caption-wrap {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.footer__top-caption-footer {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.footer__center {
		display: none !important;
	}

	.footer {
		padding-bottom: 80px;
		padding-top: 80px;
	}

	.footer.has-form {
		padding-top: 241px;
	}

	.footer__wrap {
		position: relative;
	}

	.footer__form {
		position: absolute;
		transform: translate(-50%, -50%);
	}

	.footer__top-caption {
		padding-left: 0;
		margin-bottom: 0;
	}

	.footer__wrap {
		padding-top: 0;
	}

	.footer__telegram {
		display: inline;
		padding: 8px 24px;
		font-size: 14px;
	}

	.footer__rating {
		padding: 50px 100px;
		position: relative;
		z-index: 4;
	}

	.footer__rating-wrap {
		max-width: 1180px;
		width: 100%;
		padding-top: 188px;
	}

	.footer__rating-header {
		font-weight: 600;
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 12px;
	}

	.footer__rating-text {
		font-size: 16px;
		line-height: 24px;
		max-width: 480px;
		margin-bottom: 32px;
	}

	.footer__rating-text span {
		display: none;
	}

	.footer__rating-phone-img {
		position: absolute;
		bottom: 0;
		right: 110px;
		width: 276px;
	}

	.footer__rating-phone-img img {
		max-width: 100%;
	}

	.footer__top {
		padding-left: 70px;
		padding-right: 0px;
		padding-bottom: 80px;
	}

	.footer__top::after,
	.footer__center::after {
		left: 0;
		width: 100%;
	}

	.footer__top-wrap {
		position: relative;
		flex-direction: row;
		align-items: flex-start;
	}

	.footer__logo {
		padding-left: 0;
	}

	.footer__logo-second {
		width: auto;
	}

	.footer__contacts {
		flex-direction: row;
	}

	.footer__contacts-title {
		margin-bottom: 0;
		margin-right: 17px;
		white-space: nowrap;
	}

	.footer__contacts-item {
		margin-right: 17px;
		margin-bottom: 0;
		white-space: nowrap;
	}

	.footer__contacts-item:last-child {
		margin-right: 0;
		margin-bottom: 0;
	}

	.footer__rating-wrap {
		margin-top: 0;
	}

	.footer__rating-title {
		font-size: 20px;
	}

	.footer__rating-caption {
		font-size: 16px;
	}

	.footer__rating-btn {
		width: auto;
		padding: 8px 32px;
	}

	.footer__logo-pic {
		left: -72px;
		top: 13px;
	}

	.footer__top-gradient {
		position: absolute;
		width: 228px;
		height: 185px;
		bottom: auto;
		top: -37px;
		left: auto;
		right: 208px;
		z-index: 0;
	}

	.footer__top-gradient .gradient1 {
		position: absolute;
		top: 4px;
		left: -8px;
		width: 283px;
		height: 181px;
		filter: blur(77px);
		background: rgba(213, 8, 49, 0.8);
		z-index: 2;
	}

	.footer__top-gradient .gradient2 {
		position: absolute;
		top: 55px;
		left: 80px;
		width: 180px;
		height: 180px;
		filter: blur(77px);
		background: #28acd6;
		z-index: 1;
	}

	.footer__center {
		display: flex;
		padding: 40px 0px;
	}

	.footer__bottom {
		display: flex;
		flex-direction: row;
	}

	.footer__bottom-caption {
		margin-bottom: 0;
		max-width: 540px;
	}

	.footer__copyright-and-policy {
		min-width: 320px;
		text-align: right;
	}

	.footer__top-block {
		height: 372px;
		position: relative;
	}
}

@media (max-width: 1199.9px) {
	.footer__rating-phone-img {
		right: 45px;
	}
}

@media (max-width: 991.9px) {
	.footer__copyright-and-policy {
		text-align: left;
	}

	.footer__top-links {
		display: none;
	}

	.footer__form.container {
		max-width: unset !important;
	}

	.footer__form .container {
		padding: 0 !important;
	}

	.footer__form {
		padding: 40px 28px !important;
	}

	.footer__form-wrap,
	.footer__form-fields-inputs {
		display: block;
	}

	.footer__form-info,
	.footer__form-fields,
	.footer__form-fields-input {
		width: 100% !important;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.footer__form-header {
		font-size: 24px;
		font-weight: 600;
		line-height: 32px;
		margin-bottom: 16px;
	}

	.footer__form-text {
		margin-bottom: 20px;
	}

	.footer__form-fields-input.name {
		margin-bottom: 20px;
	}

	.footer__form-button {
		width: 100%;
	}

	.footer__form-sent {
		align-items: flex-start;
		height: 138px;
	}

	.footer__form-sent-header {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 16px;
	}

	.footer__form-sent-text {
		font-size: 18px;
		font-weight: 500;
		line-height: 24px;
	}

	.footer__form-sent-button {
		width: 100%;
	}
	
	.footer__inn-and-address {
		margin-bottom: 40px;
	}
}

@media (max-width: 575.9px) {
}
