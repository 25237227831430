.count-methodics {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	background-color: #f1f1f1;
	padding: 120px 0;
}

.count-methodics .section-title{
	margin-bottom: 16px;
}

.count-methodics__info .swiper {
	overflow: visible;
}
.count-methodics__header {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 80px;
	max-width: 880px;
}
.count-methodics__text {
	font-weight: 600;
	font-size: 40px;
	line-height: 50px;
	margin-bottom: 24px;
	max-width: 780px;
}
@media (max-width: 991.9px) {
	.count-methodics {
		padding: 40px 8px;
		padding-bottom: 60px;
	}
	.count-methodics__text {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 12px;
	}
	.count-methodics__header {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 50px;
	}
}
